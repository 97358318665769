<template>
  <el-date-picker
    v-model="dateValue"
    :shortcuts="shortcuts"
		:default-time="defaultTime"
		:disabled-date="disabledDate"
    :start-placeholder="t('table.start_time')"
    :end-placeholder="t('table.end_time')"
    type="datetimerange"
    range-separator="~"
    value-format="YYYY-MM-DD HH:mm:ss"
    @change="setTime" />
</template>

<script setup>
import { ref, inject } from 'vue'

const t = inject('t')
const emit = defineEmits(['setTime'])
const dateValue = ref('')
const defaultTime = [
  new Date(new Date().getFullYear(), (new Date().getMonth() + 1), new Date().getDate(), 0, 0, 0),
  new Date(new Date().getFullYear(), (new Date().getMonth() + 1), new Date().getDate() + 1, 23, 59, 59)
]

const disabledDate = (data) => {
	return (new Date(data).getTime() > new Date().getTime())
}
const setTime = () => {
  let start_at, end_at
  if(dateValue.value) {
    const startTime = new Date(dateValue.value[0]).toISOString()
    const endTime = new Date(dateValue.value[1]).toISOString()
    // 去掉毫秒数
    start_at = `${startTime.substring(0, startTime.length - 5)}Z`
    end_at = `${endTime.substring(0, endTime.length - 5)}Z`
  } else{
    start_at = ''
    end_at = ''
  }
  emit('setTime', [start_at, end_at])
}
// 清除时间
const clearTime = () => {
  dateValue.value = null
}
const shortcuts = [
  {
    text: t('form.last_week'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    }
  },
  {
    text: t('form.last_month'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    }
  },
  {
    text: t('form.last_three_month'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      return [start, end]
    }
  }
]
defineExpose({
  clearTime: clearTime
})
</script>

<style lang="scss" scoped>
.time-picker {
  width: 480px !important;
}
</style>