<template>
	<router-view />
</template>

<script setup>
</script>
<style lang="scss">
	// 全局样式
	@import '@/common/index.scss';
</style>
