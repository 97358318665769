<template>
  <svg :class="svgClass" aria-hidden="true">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script setup>
import { computed } from 'vue'
const props = defineProps({
  iconClass: {
    type: String,
    default: ''
  },
  className: {
    type: String,
    default: ''
  }
})
const img = computed(() => {
  return require(`@/icons/svg/${props.iconClass}.svg`)
})
const iconName = computed(() => {
  return `#icon-${props.iconClass}`
})
const svgClass = computed(() => {
  return props.className ? `svg-icon ${props.className}` : 'svg-icon'
})
</script>

<style scoped lang="scss">
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  font-size: 16px;
  margin-right: 8px;
}
</style>
