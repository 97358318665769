import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as Icons from '@element-plus/icons-vue'
import { createI18n } from 'vue-i18n'
const localeZH = require('element-plus/lib/locale/lang/zh-cn')
const localeEN = require('element-plus/lib/locale/lang/en')
import messages from '../lang' // Internationalization
// 获取浏览器默认语言
export const getBrowserLang = () => {
  const browserLang = navigator.language ? navigator.language : navigator.browserLanguage
  let defaultBrowserLang = ''
  if (browserLang.toLowerCase() === 'cn' || browserLang.toLowerCase() === 'zh' || browserLang.toLowerCase() === 'zh-cn') {
    defaultBrowserLang = 'zh-CN'
  } else {
    defaultBrowserLang = 'en'
  }
  localStorage.setItem('language', defaultBrowserLang)
  return defaultBrowserLang
}
// 设置语言 - 用户未手动切换语言时默认为当前浏览器语言
const language = localStorage.getItem('language') ? (['zh-cn', 'cn', 'zh'].includes(localStorage.getItem('language').toLowerCase()) ? 'zh-CN' : 'en') : getBrowserLang()
export const i18n = createI18n({
  legacy: false,
  locale: language,
  messages
})
export default (app) => {
  const elIcons = { ...Icons }
  for (const name in elIcons) {
    app.component(name, elIcons[name])
  }
  app.use(ElementPlus, { locale: language === 'en' ? localeEN.default : localeZH.default })
  // 在provide中提供i18n的t方法
  app.provide('t', i18n.global.t)
  app.use(i18n)
}