import Pager from "./pager/Pager"
import CustomTable from './table'
import CustomForm from './form'
import CustomTime from './time'
import CustomOrderList from './orderList'
import OrderListAssi from './orderListAssi'
import LangSelect from './langSelect'
import CustomDetail from './detail'
import CreateUser from './CreateUser/index.vue'
import SvgIcon from './SvgIcon/index.vue' // 全局注册svg-icon组件

export default {
	install: function(Vue) {
		Vue.component('Pager', Pager)
		Vue.component('custem-table', CustomTable)
		Vue.component('custem-form', CustomForm)
		Vue.component('custem-detail', CustomDetail)
		Vue.component('custem-time', CustomTime)
		Vue.component('custem-orderList', CustomOrderList)
		Vue.component('order-listAssi', OrderListAssi)
		Vue.component('lang-select', LangSelect)
		Vue.component('create-user', CreateUser)
		Vue.component('svg-icon', SvgIcon)
	}
}
