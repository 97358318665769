<template>
  <el-dialog v-model="winShow" :close-on-click-modal="false" :title="t('assign.detail')" :close-on-press-escape="false"
    width="900px" @close="closeWin">
    <custem-detail :loading="detailLoading" :descriptions="descriptions">
      <template #send_email>
        <!-- 发送邮箱 -->
        <send-mail ref="sendMailRef" type="assignment" :detail="assignDetail" />
      </template>
      <!-- 发送自定义邮箱 -->
      <template #custom_mail>
        <!-- 发送邮箱 -->
        <send-mail ref="sendMailRef1" mailType="custem" type="assignment" :detail="assignDetail" />
      </template>
      <template #send_issuse>
        <!-- 授权下发状态 -->
        <send-issuse ref="sendIssuseRef" :detail="assignDetail" />
      </template>
      <template #failback_secret>
        <!-- 有回切注册码，但无回切激活码 -->
        <div v-if="!assignDetail.failback_secret && assignDetail.failback_key" @click="setFailbackSecret" class="set_failback_key">{{ t('assign.generate_failback_secret') }}</div>
        <div v-else> {{ assignDetail.failback_secret || '-' }} </div>
      </template>
    </custem-detail>
    <template v-if="showAssignList">
      <!-- 分配历史表格/详情显示 -->
      <el-divider content-position="center">{{ t('assign.project_assign_history') }}</el-divider>
      <div class="tables">
        <custem-table
          :tableInfo="tableInfo"
          :tableLoading="tableLoading"
          height="400px"
          @getList="getAssignmentList"></custem-table>
      </div>
    </template>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeWin">{{ t('form.close') }}</el-button>
      </div>
    </template>
    <!-- 分配列表详情 -->
    <Detail2 ref="detail2" />
  </el-dialog>
</template>

<script setup>
import { ref, inject, reactive, getCurrentInstance, nextTick } from 'vue'
import SendMail from '../../components/send_email.vue'
import SendIssuse from '../../components/send_issuse.vue'
import { AuthManagement } from '@/utils/api'
import Detail2 from './Detail2.vue'
const t = inject('t')
const winShow = ref(false)
const detailLoading = ref(false) // 详情loading
const tableLoading = ref(false) // 分配表格loading
const assignDetail = ref({}) // 申请详情信息
const sendMailRef = ref()
const sendMailRef1 = ref()
const sendIssuseRef = ref()
const detail2 = ref()
const showAssignList = ref(true)
const project_id = ref(null) // 项目ID
const { proxy } = getCurrentInstance()
const descriptions = reactive([
  { // 分配时间
    label: t('order.allocation_time'),
    prop: 'created_at'
  },
  { // 授权编号
    label: t('assign.license_code'),
    prop: 'id'
  },
  { // 所属项目
    label: t('assign.project'),
    prop: 'project_name'
  },
  { // 产品名称
    label: t('product.product_name'),
    prop: 'product_name'
  },
  { // 订单编号
    label: t('order.order_code'),
    prop: 'order_code'
  },
  { // 订单类别
    label: t('order.order_type'),
    prop: 'order_type'
  },
  { // 分配数量
    label: t('order.allocation_count'),
    prop: 'content_value'
  },
  { // 使用周期
    label: t('order.order_cycle'),
    prop: 'order_use'
  },
  { // 使用到期时间
    label: t('table.expiration_time'),
    prop: 'expired_at'
  },
  { // 所属子用户
    label: t('project.sub_user'),
    prop: 'sub_user'
  },
  { // 产品用户
    label: t('assign.product_users'),
    prop: 'saas_user',
    span: 2
  },
  {
    label: t('assign.activated_count'),
    prop: 'activated_count',
    hidden: true
  },
  {
    label: t('assign.inactive_count'),
    prop: 'inactive_count',
    hidden: true
  },
  {
    label: t('assign.authorized_count'),
    prop: 'authorized_count',
    hidden: true
  },
  {
    label: t('assign.unauthorized_count'),
    prop: 'unauthorized_count',
    hidden: true
  },
  {
    label: t('project.license'), // 注册码
    prop: 'lic_key',
    span: 2
  },
  {
    label: t('project.failback_key'), // 回切注册码
    prop: 'failback_key',
    span: 2,
    hidden: true
  },
  {
    label: t('assign.license_secret'), // 激活码
    prop: 'lic_secret',
    span: 2,
    hidden: true
  },
  {
    label: t('assign.failback_secret'), // 回切激活码
    prop: 'failback_secret',
    type: 'slot',
    span: 2,
    hidden: true
  },
  { // 发送邮箱
    label: t('assign.send_email'),
    prop: 'send_email',
    type: 'slot',
    span: 2
  },
  {
    label: t('assign.custom_mail'),
    prop: 'custom_mail',
    type: 'slot',
    span: 2
  },
  { // 授权下发状态
    label: t('assign.issuance_status'),
    prop: 'send_issuse',
    type: 'slot',
    span: 2
  },
  { // 备注
    label: t('user.notes'),
    prop: 'comment'
  }
])
// 分配历史表格
const tableInfo = reactive({
  header: [
    { // 项目名称
      key: 'project_name',
      label: t('project.project_name'),
      custem: (data) => {
        return data.project ? data.project.name : '-'
      }
    },
    { // 分配数量
      key: 'hosts',
      label: t('assign.assigned_count'),
      custem: (data) => {
        return data.content ? `${proxy.$numberToCurrencyNo(data.content.hosts)} ${t('unit.ge')}` : '-'
      }
    },
    { // 分配日期
      key: 'created_at',
      label: t('order.allocation_time')
    },
    { // 使用到期时间
      key: 'expired_at',
      label: t('order.expiration_time')
    },
    {
      label: t('table.action'),
      width: '80px',
      fixed: 'right',
      handleFun: [
        {
          name: t('table.detail'),
          fn: (data) => {
            detailData(data)
          }
        }
      ]
    }
  ],
  data: []
})
// 分配历史详情
const detailData = async (data) => {
  await nextTick()
  detail2.value.openWin(data, false)
}
// 获取授权详情
const getAssignDetail = async (id) => {
  detailLoading.value = true
  try {
    const res = await AuthManagement.AssignmentDetail(id)
    assignDetail.value = res
    descriptions.forEach(item => {
      if (item.prop === 'project_name') {
        item.value = res.project && res.project.name || '-'
      } else if (item.prop === 'product_name') {
        item.value = res.order ? `${res.order.product.deployment.name}/${res.order.product.category.name}/${res.order.product.name}` : '-'
      } else if (item.prop === 'order_code') {
        item.value = res.order && res.order.code || '-'
      } else if (item.prop === 'order_type') {
        item.value = proxy.$formatType(res.order.category_id, res.order.params.service_type)
      } else if (item.prop === 'content_value') {
        item.value = res.content ? `${proxy.$numberToCurrencyNo(res.content.hosts)} ${t('unit.ge')}` : '-'
      } else if (item.prop === 'order_use') {
        item.value = `${res.order && proxy.$numberToCurrencyNo(res.order.use_period)} ${Number(res.order.use_period) > 1 ? t('system.days') : t('system.day')}` || '-'
      } else if (item.prop === 'sub_user') {
        item.value = res.sub_user ? res.sub_user.name : '-'
        item.hidden = !!!res.sub_user
      } else if (item.prop === 'saas_user') {
        item.value = res.project && res.project.params.saas_name || '-'
      } else if (['lic_key', 'lic_secret'].includes(item.prop)) {
        item.hidden = !(res.order.params.service_type !== 'failback')
        item.value = res[item.prop] || '-'
      } else if (['failback_key'].includes(item.prop)) {
        item.hidden = !res[item.prop]
        item.value = res[item.prop] || '-'
      } else if (['failback_secret'].includes(item.prop)) {
        item.hidden = !res['failback_key']
        item.value = res[item.prop] || '-'
      } else {
        if (!['activated_count', 'inactive_count', 'authorized_count', 'unauthorized_count'].includes(item.prop)) item.value = res[item.prop] || '-'
      }
    })
    // 获取分配历史
    getAssignmentList({ page: 1 })
    // 只有绑定产品用户的调用
    if (res.project.params.saas_name) {
      getSaasUserinfo(id)
    }
    await nextTick()
    sendMailRef.value.sendMailList() // 查询发送邮箱
    sendMailRef1.value.sendMailList() // 查询发送邮箱
    detailLoading.value = false
  } catch (error) {
    detailLoading.value = false
  }
}
// 获取saas用户相关信息
const getSaasUserinfo = async (id) => {
  detailLoading.value = true
  try {
    const license = await AuthManagement.getSaasLicense(id)
    // 回切、迁移
    descriptions.forEach(item => {
      if (['activated_count', 'inactive_count', 'authorized_count', 'unauthorized_count'].includes(item.prop)) {
        if (item.prop === 'activated_count') {
          item.value = ''
          item.value += license.dr ? `${t('assign.dr')}: ${license.dr.used === 0 ? '0' : license.dr.used}` : license.failback ? `${t('assign.failback')}: ${license.failback.used === 0 ? '0' : license.failback.used}` : ''
          item.hidden = !(assignDetail.value.project.product.category.saas_alias !== 'migration')
        } else if (item.prop === 'inactive_count') {
          item.value = ''
          item.value += license.dr ? `${t('assign.dr')}: ${license.dr.unused === 0 ? '0' : license.dr.unused}` : license.failback ? `${t('assign.failback')}: ${license.failback.unused === 0 ? '0' : license.failback.unused}` : ''
          item.hidden = !(assignDetail.value.project.product.category.saas_alias !== 'migration')
        } else if (item.prop === 'authorized_count') {
          item.value = license.migration.used || '-'
          item.hidden = assignDetail.value.project.product.category.saas_alias !== 'migration'
        } else if (item.prop === 'unauthorized_count') {
          item.value = license.migration.unused || '-'
          item.hidden = assignDetail.value.project.product.category.saas_alias !== 'migration'
        }
      }
    })
    detailLoading.value = false
  } catch (error) {
    detailLoading.value = false
  }
}
// 获取分配历史表格
const getAssignmentList = async (search = {}) => {
  if (!project_id.value) return
  tableLoading.value = true
  try {
    const res = await AuthManagement.AssignmentList({
      filter_project_id: project_id.value,
      ...search
    })
    tableInfo.data = res.items.filter(item => item.id !== assignDetail.value.id)
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

// 生成回切激活码
const setFailbackSecret = async () => {
  if (!assignDetail.value.failback_key) return
  const res = await AuthManagement.SetFailBack({
    assignment_id: assignDetail.value.id,
    failback_key: assignDetail.value.failback_key
  })
  assignDetail.value.failback_secret = res.failback_secret
}
// 关闭弹框
const closeWin = () => {
  winShow.value = false
  project_id.value = ''
}
// 打开弹框
const openWin = (data, showAssignTable) => {
  winShow.value = true
  if (showAssignTable) {
    project_id.value = data.project.id
  }
  showAssignList.value = showAssignTable
  getAssignDetail(data.id)
}

defineExpose({
  openWin
})
</script>
<style lang="scss" scoped>
.set_failback_key {
  color: var(--el-color-primary);
  cursor: pointer;
  font-size: 12px;
}
</style>