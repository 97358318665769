
import { Login } from '@/utils/api.js'
import { ElMessage } from 'element-plus'
import { LoginByID } from '@/utils/api'
import { i18n } from '@/plugins/element'
export default {
  // 登录
  Login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      Login(userInfo).then(res => {
        commit('SET_USER', res)
        resolve()
      })
        .catch(error => {
          reject(error)
        })
    })
  },
  // 退出登录前端退出，无接口调用
  LOG_OUT({ commit }) {
    commit('CLEAR_USER', null)
  },
  // 返回到销售用户界面
  backToSales({ commit }, data) {
    return new Promise((resolve, reject) => {
      LoginByID(data.id).then(res => {
        ElMessage.success(i18n.global.t('form.handleSuccess'))
        res.noBack = data.noBack ? data.noBack : null // 存储是否跳转路由
        commit('CLEAR_ROUTER') // 清除路由
        commit('CLEAR_ESCROW_ID') // 清除代管企业销售id
        commit('SET_USER', res) // 设置用户信息
        resolve(true)
      })
    })
  }
}