// 表单、筛选框数据
import {
  enterpriseManagement,
  userManagement,
  productManagement,
  AuthManagement
} from '@/utils/api'

// 获取企业列表
export const getEnterpriseList = (search = {}) => {
  return new Promise(resolve => {
    enterpriseManagement.EnterpriseList({
      ...search,
      is_paginate: false,
      filter_status: 1
    }).then(res => {
      let arr = []
      res.items.forEach(ele => {
        arr.push({
          label: ele.name,
          value: ele.id,
          sales_user_id: ele.sales_user_id
        })
      })
      resolve(arr)
    })
  })
}

// 获取用户列表
export const getUserList = (search = {}) => {
  return new Promise(resolve => {
    userManagement.roleUserList({
      ...search,
      is_paginate: false
    }).then(data => {
      let arr = []
      data.items.forEach(ele => {
        arr.push({
          label: ele.name,
          value: ele.id
        })
      })
      resolve(arr)
    })
  })
}

// 获取企业产品列表
export const getEnterpriseProductList = (enterprise_id) => {
  return new Promise(resolve => {
    productManagement.EnterpriseProduct({
      filter_enterprise_id: enterprise_id,
      is_paginate: false
    }).then(res => {
      if (res.items.length) {
        let arr = []
        res.items.forEach(ele => {
          arr.push({
            label: `${ele.product.deployment.name}/${ele.product.category.name}/${ele.product.name}`,
            value: ele.product.id,
            ...ele
          })
        })
        resolve(arr)
      } else {
        resolve([])
      }
    })
  })
}

// 获取用户产品列表
export const getUserProduct = (search = {}) => {
  return new Promise(resolve => {
    productManagement.ProductList({
      ...search,
      is_paginate: false
    }).then(res => {
      if (res.items.length) {
        let arr = []
        res.items.forEach(ele => {
          arr.push({
            label: `${ele.deployment.name}/${ele.category.name}/${ele.name}`,
            value: ele.id
          })
        })
        resolve(arr)
      } else {
        resolve([])
      }
    })
  })
}

// 获取销售产品列表
export const getSalesProduct = (user_id) => {
  return new Promise(resolve => {
    productManagement.SalesProductList({
      filter_user_id: user_id,
      is_paginate: false
    }).then(res => {
      if (res.items.length) {
        let arr = []
        res.items.forEach(ele => {
          arr.push({
            label: `${ele.product.deployment.name}/${ele.product.category.name}/${ele.product.name}`,
            value: ele.product.id
          })
        })
        resolve(arr)
      } else {
        resolve([])
      }
    })
  })
}

// 获取企业项目列表
export const getProjectList = (search = {}) => {
  return new Promise(resolve => {
    AuthManagement.ProjectList({
      ...search,
      is_paginate: false,
      filter_effectuate: true
    }).then(res => {
      if (res.items.length) {
        resolve(res.items)
      } else {
        resolve([])
      }
    })
  })
}
// 获取浏览器默认语言
export function getBrowserLang() {
  const browserLang = navigator.language ? navigator.language : navigator.browserLanguage
  let defaultBrowserLang = ''
  if (browserLang.toLowerCase() === 'cn' || browserLang.toLowerCase() === 'zh' || browserLang.toLowerCase() === 'zh-cn') {
    defaultBrowserLang = 'zh-cn'
  } else {
    defaultBrowserLang = 'en'
  }
  return defaultBrowserLang
}