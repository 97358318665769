import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import state from './state'
import mutations from './mutations'
import actions from './actions'

// 配置缓存模式
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default createStore({
  state,
  mutations,
  actions,
  plugins: [vuexLocal.plugin]
})
