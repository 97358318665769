
import { i18n } from '@/plugins/element'
const t = i18n.global.t

const validateRule = (rule, modleVal, callback) => {
  if (!!!modleVal.value && modleVal.value !== 0 && modleVal.value !== false) {
    // 当前项不能为空
    callback(new Error(t('form.emptyErrMsg')))
  } else if (modleVal.prop && modleVal.prop.includes('mail')) { // 邮箱校验
    // 正则表达式用于验证邮箱格式
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return emailPattern.test(modleVal.value) ? callback() : callback(new Error(t('form.error_mail')))
  } else {
    callback()
  }
}
export const rules = [
  { required: true, trigger: 'blur' },
  { validator: validateRule }
]