
import router from '@/router'

export default {
  // 设置用户信息
  SET_USER(state, data) {
    state.users = data
    state.role = data.role.alias
    state.authorization = data.api_key
    // 跳转到首页
    if (!data.noBack)
      router.push({ path: '/' })
  },
  // 设置路由
  SET_ROUTES(state, data) {
    state.addRoutes = data
  },
  // 清除路由
  CLEAR_ROUTER(state, data) {
    state.addRoutes = []
  },
  // 清除用户信息
  CLEAR_USER(state) {
    state.users = {}
    state.authorization = null
    state.role = null
    state.salesLoginId = null
    window.history.pushState(null, null, location.href)
    router.push({ path: '/login' })
  },
  // 设置代管销售Id
  SET_ESCROW_ID(state, id) {
    state.salesLoginId = id
  },
  // 设置代管销售Id
  CLEAR_ESCROW_ID(state, id) {
    state.salesLoginId = null
  }
}