export const themeColor = {
  primary: '#CE1B85',
  primaryLight: '#F555B4',
  primaryDark: '#922164',
  warning: '#F3C937',
  success: '#33CC33',
  error: '#CC3333',
  disabled: '#eee',
  default: '#61AEF6',
  enterprisePrimary: '#A94581', // 代管企业导航主色
  other1: '#DFDDDE',
  other2: '#DE9ACA',
  other3: '#DFDDDE',
  other4: '#CE1B85',

  fontSize16: '16px',
  fontSize14: '14px',
  fontSize13: '13px',

  color1: '#222222',
  color2: '#3A3A3A',
  color3: '#525252',
  color4: '#7F7F7F',
  color5: '#ECECEC',
  color6: '#F2F2F2',
  color7: '#BCBCBC',
  color8: '#D8D8D8',
  black: '#000',
  white: '#fff',
  inputRadius: '5px',
  baseFontSize: '12px'
}

const root = document.querySelector(':root')
root.style.setProperty('--el-color-primary', themeColor.primary)
root.style.setProperty('--el-color-success', themeColor.success)
root.style.setProperty('--el-color-danger', themeColor.error)
root.style.setProperty('--el-color-error', themeColor.error)
root.style.setProperty('--el-border-color', themeColor.color7)
root.style.setProperty('--el-border-radius-base', themeColor.inputRadius)
root.style.setProperty('--el-text-color-regular', themeColor.color3)
root.style.setProperty('--el-font-size-base', themeColor.baseFontSize)

// 默认导航样式
export const menuTheme = {
  menuBgColor: themeColor.color2, // 主题色
  menuDefaultColor: themeColor.color7, // 导航默认文字颜色
  menuActiveColor: themeColor.white, // 导航选中默认色
  escrowColor: themeColor.enterprisePrimary, // 销售代管企业导航色
  escrowmenuDefaultColor: themeColor.white, // 销售代管企业导航默认文字颜色
  escrowmenuActiveColor: themeColor.primaryLight, // 销售代管企业导航文字选中颜色
  salesLogoBg: themeColor.other4, // 销售代管企业logo
  white: themeColor.white,
  backOpacity: 'rgba(0, 0, 0, .37)'
}