<template>
  <el-dialog
    v-model="winShow"
    width="800px"
    :close-on-click-modal="false"
    :close-on-press-escape="false">
    <template #header>
      <div class="headers">
        <el-icon>
          <WarningFilled />
        </el-icon>
        <span>{{ t('assign.license_assign_failed') }}</span>
      </div>
    </template>
    <div style="margin-bottom: 10px;">{{ t('assign.assignment') }}</div>
    <custem-detail :loading="detailLoading" :descriptions="descriptions" />
    <template #footer>
      <div class="warning">
        <el-icon>
          <WarningFilled />
        </el-icon>
        <span>{{ result || '-' }}</span>
      </div>
      <div class="dialog-footer">
        <el-button @click="closeWin">{{ t('form.close') }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, inject } from 'vue'
import { AuthManagement } from '@/utils/api'

const detailLoading = ref(true)
const t = inject('t')
const winShow = ref(false)
const result = ref('')

const descriptions = reactive([
  { // 项目名称
    label: t('project.project_name'),
    prop: 'project_name'
  },
  { // 产品名称
    label: t('product.product_name'),
    prop: 'product_name'
  },
  { // 已选订单
    label: t('assign.selected_order'),
    prop: 'order_code'
  },
  { // 分配数量
    label: t('order.allocation_count'),
    prop: 'content_value'
  },
  { // 产品用户
    label: t('assign.product_users'),
    prop: 'saas_user'
  }
])

// 获取详情接口
const getFailDetail = async (id) => {
  detailLoading.value = true
  try {
    const res = await AuthManagement.AssignmentDetail(id)
    descriptions.forEach(item => {
      if (item.prop === 'product_name') {
        item.value = `${res.order.product.deployment.name}/${res.order.product.category.name}/${res.order.product.name}` || '-'
      } else if (item.prop === 'project_name') {
        item.value = res.project.name || '-'
      } else if (item.prop === 'order_code') {
        item.value = res.order.product.code || '-'
      } else if (item.prop === 'content_value') {
        item.value = res.content.value || '-'
      } else if (item.prop === 'saas_user') {
        item.value = res.params.saas_user || '-'
      } else {
        item.value = res[item.prop] || '-'
      }
    })
    if (res.task.result) {
      result.value = res.task.result
    }
    detailLoading.value = false
  } catch (error) {
    detailLoading.value = false
  }
}
// 打开弹框
const openWin = (data) => {
  winShow.value = true
  getFailDetail(data)
}

// 关闭弹框
const closeWin = () => {
  winShow.value = false
}

defineExpose({
  openWin: openWin
})
</script>
<style lang="scss">
.headers {
  display: flex;
  align-items: center;
  color: #F56C6C;
  font-size: 22px;

  span {
    color: #333;
    margin-left: 5px;
    font-size: 16px !important;
  }
}

.warning {
  display: flex;
  margin-top: 10px;
  display: flex;
  font-size: 22px;
  align-items: center;
  color: #F56C6C;

  span {
    color: #333;
    margin-left: 5px;
    font-size: 14px !important;
  }
}
</style>
