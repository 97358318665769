export default {
  system: {
    name: "System Manage",
    no_permission:'You currently do not have system operation permissions. Please contact the administrator.',
    welcome: 'Welcome',
    message: 'Message',
    unread: 'Unread',
    no_message: 'No messages',
    role: 'Role',
    changePwd: 'Change Password',
    logout: 'Logout',
    account: 'Account',
    userinfo: 'Userinfo',
    day: 'Day',
    days: 'Days',
    month: 'Month',
    year: 'Year',
    title_content: 'Title Content',
    status: 'Status',
    read: 'Read',
    unread: 'Unread',
    type: 'Type',
    system_notice: 'System Notification',
    del_all: 'Sure to delete all notifications?',
    del_select: 'Sure to delete the selected notifications?',
    del_notice: 'Delete Notification',
    del_all_button: 'Delete All',
    read_button: 'Read',
    id: 'ID',
    phone_number: 'Phone Number',
    action: 'Action',
    user: 'User',
    instructions: 'Instructions',
    language: 'Language',
    zh: 'Chinese',
    en: 'English',
    old_pwd: 'Old Password',
    new_pwd: 'New Password',
    new_pwd_again: 'Confirm New Password',
    not_right: 'The passwords do not match.',
    network_error: 'Request timed out, please check your network connection.',
    network_500: 'Internal server error.',
    tip: 'Tip',
    error_404: 'Requested resource not found.'
  },
  role: {
    role1: 'Administrator',
    role2: 'Operations',
    role3: 'Sales',
    role4: 'Enterprise',
    role5: 'Enterprise Sub-User',
    unkown: 'Unknown User'
  },
  lang: {
    zh: '简体中文',
    en: 'English'
  },
  routes: {
    dashboard: 'Dashboard',
    user: 'User',
    enterprise: 'Enterprise',
    enterprise_user: 'Enterprise User',
    product: 'Product',
    authorization: 'Authorization',
    enterprise_product: 'Enterprise Product',
    project: 'Project',
    behalf_project: 'Project',
    order: 'Order',
    behalf_order: 'Order',
    general: 'General',
    salesman: 'Salesman',
    subuser: 'Subuser',
    third_oauth: 'Third Oauth',
    sales_product: 'Sales',
    test_project: 'Trial project',
    assignment: 'Assignment',
    behalf_auth: 'Assignment',
    application: 'Application',
    audit: 'Audit',
    backToSales: 'Back to sales',
    message: 'System Notification',
    userinfo: 'Userinfo'
  },
  // login from 
  form: {
    inputPlaceholder: "Please input your value",
    selectPlaceholder: "Please Select",
    username: 'Please input username',
    usernameCheck: 'Must be between 3 and 30 characters.',
    password: "Please input password",
    loginBtn: "Sign In",
    wrongUsername: "Length should be 3 to 5",
    thirdPartyLogin: 'Third-Party Login',
    success: 'Login Successful',
    handleSuccess: 'Switch Successful',
    submit: 'Submit',
    cancel: 'Cancel',
    search_placeholder: 'Please Enter',
    last_week: 'The Last Week' ,
    last_month: 'The Last Month' ,
    last_three_month: 'Last 3 Months',
    emptyErrMsg: 'The current item cannot be empty.',
    pwdMessage: 'Two inconsistencies in password input!',
    error_mail: 'Email format is incorrect.',
    must_be_number: 'Only numbers can be entered.',
    must_be_number_letter: 'Only letters or numbers can be entered.',
    create_success: 'Creation Successful',
    update_success: 'Update Successful',
    next: 'Next',
    prev: 'Prev',
    save: 'Save',
    delete: 'Delete',
    close: 'Close',
    sure_del: 'Are you sure to delete the data?',
    confirm: 'confirm',
    check: 'Audit',
    revoke: 'Revoke',
    agree: 'Agree',
    reject: 'Reject',
    pass: 'Approve',
    tip: 'Tip',
    select: 'Select',
    add: 'Add',
    username: 'Username'
  },
  table: {
    creation_time: 'Creation Time',
    expiration_time: 'Expiration Date',
    submission_time: 'Submission Time',
    registration_time: 'Registration Time',
    last_login_time: 'Last Login Time',
    login_time: 'Login Time',
    start_time: 'Start Time',
    end_time: 'End Time',
    apply_time: 'Application Time',
    action: 'Action',
    detail: 'Detail',
    modify: 'Modify',
    create: 'Create',
    custody: 'Custody',
    show_more: 'Show More',
    yes: 'Yes',
    no: 'No',
    export: 'Export',
    export_success: 'Export Successful',
    export_failed: 'Export Failed',
    add_success: 'Creation Successful',
    modify_success: 'Modification Successful',
    bind_success: 'Binding successful',
    del_success: 'Delete Successful',
    del_failed: 'Delete Failed',
    select_del: 'Please select the data to delete.',
    all: 'All',
    transfer_successful: 'Transfer into successful',
    transfer_out_successful: 'Transfer out Successful',
    transfer_out: 'Transfer Out',
    enable: 'Enable'
  },
  dashboard: {
    expired_license: 'Recently Expired License',
    enterprise: 'Enterprise',
    project: 'Project',
    sales: 'Sales',
    quantity: 'Quantity',
    order_id: 'Order ID'
  },
  user: {
    sure_jump_before: 'Sure to go to the enterprise user',
    sure_jump_after: 'management page?',
    jump_tip: 'Tip',
    username: 'Username',
    liaison_name: 'Liaison Name',
    liaison: 'Liaison',
    liaison_mail: 'Liaison Mail',
    liaison_phone: 'Liaison Phone Number',
    sales: 'Sales',
    enterprise_user: 'Enterprise User',
    enterprise_username: 'Enterprise Username' ,
    password: 'Password',
    enterprise_name: 'Enterprise',
    confirm_password: 'Confirm Password',
    nick_name: 'Nick Name',
    enterprise_nick_name: 'Nick Name',
    enterprise_Liaison: 'Liaison',
    enterprise_liaison_phone: 'Liaison Phone Number',
    enterprise_liaison_mail: 'Liaison Mail',
    custody: 'Custody',
    custody_tip: 'Once enabled, enterprise users cannot access the platform. All operations will be managed by their assigned sales representative.',
    notes: 'Notes',
    enterprise_user_detail: 'Enterprise User Details',
    sales_name: 'Sales',
    entry_time: 'Entry Time',
    exit_time: 'Exit Time',
    edit_userinfo: 'Modify Userinfo',
    allow_phone: 'Allow the phone to receive SMS notifications from the platform.',
    allow: 'Allow',
    not_allow: 'Not Allow',
    dingtalk_phone: 'DingTalk Phone Number',
    exist_sale_product: 'This product already exists under this sale.',
    create_sale: 'Create Sale',
    modify_sale: 'Modify Sale',
    create_sale_user: 'Create Sale User',
    third_party: 'Third-Party',
    union_id: 'Union ID',
    third_id: 'Third-Party ID',
    common_sub_user: 'Shared Sub-user',
    create_sub_user: 'Create Sub-user',
    edit_sub_user: 'Modify Sub-user',
    sub_user_detail: 'Sub-user Detail',
    sub_user_mail: 'Sub-user Mail',
    sub_user: 'Sub-user',
    sub_user_name: 'Name',
    sub_user_phone: 'Phone Number',
    is_first: 'First Application',
    name: 'Name'
  },
  enterprise: {
    name: 'Enterprise Name',
    creator: 'Creator',
    full_name: 'Please enter the full name',
    code: 'Code',
    review_status: 'Review Status',
    related_products: 'Related Products',
    create: 'Create Enterprise',
    detail: 'Enterprise Detail',
    modify: 'Modify Enterprise',
    authorize_product: 'Authorize Product',
    add_user: 'Add Enterprise User',
    social_code: 'Unified Social Credit Code (USCC)',
    social_code_tip: 'As shown on the business license',
    code_tip: 'The code needs to be confirmed and filled in by operations.',
    type: 'Enterprise Type',
    type_tip: 'This enterprise type has already been created. Please choose another one.',
    general: 'General',
    testing: 'Internal Testing',
    scan: 'Scan-to-Try',
    code_type: 'The code must consist of letters.',
    status: 'Status',
    no_data: 'Failed to retrieve company information. Addition unsuccessful.',
    exist_product: 'This product already exists under this company',
    select_enterprise: 'Please select a enterprise.',
    select_product: 'Please select a product.',
    transfer_enterprise: 'Transfer Enterprise',
    bind_dingtalk: 'Bind DingTalk',
    entry_enterprise: 'Entry Enterprise',
    exit_enterprise: 'Exit Enterprise',
    transfer: 'Enterprise Transfer',
    entry_enterprise_user: 'Entry Enterprise User',
    exit_enterprise_user: 'Exit Enterprise User',
    info: 'Enterprise Information',
    code_length: 'The enterprise code length must be between 2 and 20 characters.',
    code_type: 'The enterprise code must consist of letters.',
    creator: 'Creator Name',
    creator_username: 'Creator Username',
    creator_phone: 'Creator Phone Number',
    reject_create_apply: 'Sure to reject this enterprise\'s creation application?',
    pass_create_apply: 'Sure to approve this enterprise\'s creation application?',
    status_0: 'Pending Review',
    status_1: 'Approved'
  },
  product: {
    product_category: 'Product Category',
    product_type: 'Product Type',
    product_name: 'Product Name',
    product_code: 'Product Code',
    show_product: 'View Products',
    add_product: 'Add Products',
    product_abbreviation: 'Product abbreviation',
    product_full_name: 'Product Fullname',
    type_abbreviation: 'Type Abbreviation',
    category_abbreviation: 'Category Abbreviation',
    version: 'Version',
    edit: 'Modify Product',
    create: 'Create Product',
    saas: 'SAAS',
    stda: 'STDA',
    coem: 'COEM',
    product_model: 'Product Model',
    general: 'General',
    in_house: 'Developed',
    scan_to_try: 'Scan-to-Try',
    select_product_model: 'Please select product model',
    select_product_category: 'Please select product category',
    has_model: 'This product model has been created.',
    custom_setting: 'Custom Settings',
    auth_address: 'Auth URL',
    admin_username: 'Administrator Username',
    admin_password: 'Administrator Password',
    allow_auto: 'Allow automatic issuance of license',
    select_sale: 'Please select sale',
    select_product: 'Please select product',
    create_select_pro: 'Select Product',
    user_product: 'Product Username'
  },
  order: {
    type_1001: 'New',
    type_1002: 'Reissue',
    type_1003: 'Test',
    status_0: 'Pending Confirmation',
    status_1: 'Pending Review',
    status_2: 'Approved',
    status_8: 'Cancelled',
    status_9: 'Rejected',
    order_total: 'Order Count',
    license_total: 'Available Licenses',
    order_create: 'Order Creation Time',
    order_user: 'Order Initiator',
    order_code: 'Order ID',
    order_type: 'Order Type',
    can_use: 'Available',
    ass_end_time: 'Assigned Expiration Time',
    order_cycle: 'Usage Cycle',
    approval_status: 'Approval Status',
    order_status: 'Order Status',
    available: 'Available',
    unavailable: 'Unavailable',
    failback: 'Failback',
    order: 'Order',
    bind_project: 'Bind Project',
    comment: 'Comment',
    add_comment: 'Add Comment',
    add_comment_success: 'Comment Successful.',
    revoke_order: 'Revoke Order',
    revoke_success: 'Revocation Successful',
    show_project: 'View Project',
    related_projects: 'Related Projects',
    add_project: 'Add Project',
    order_detail: 'Order Detail',
    allocation_history: 'Order Allocation History',
    asse_cycle: 'Allocation Period',
    asse_cycle_tip: 'The allocation period refers to the timeframe during which authorized allocations can be created after an order becomes effective. Orders will become invalid if the allocation period is exceeded.',
    use_cycle: 'The usage period refers to the valid duration of the software license. The start time of the usage period is counted from the day the software license is successfully allocated on this platform and added to the Oneprocloud migration or disaster recovery platform.',
    allocation_count: 'Allocation Count',
    allocation_time: 'Allocation Time',
    expiration_time: 'Expiration Time',
    approval_at: 'Approval Time',
    original_order_code: 'Original Order Code',
    approve_order: 'Sure to approve this order?',
    action_order: 'Sure to action this order?',
    confirm_order: 'Sure to confirm this order?',
    create_order: 'Create Order',
    select_enterprise_create: 'Enterprise',
    select_enterprise_user_create: 'Enterprise User',
    select_product_create: 'Select Products',
    select_origional_order: 'Original Order',
    select_service_type: 'Service Type',
    buy_num: 'Quantity',
    standard: 'Standard',
    jump_and_confirm: 'Proceed to sales for order confirmation?',
    delivery_method: 'Delivery Method',
    pay_and_post: 'Send Upon Payment',
    post_and_pay: 'Payment After Delivery',
    expected_payment_time: 'Expected Payment Time',
    is_stamp: 'Seal',
    stamp_method: 'Stamp method',
    real_stamp: 'Physical Seal',
    ele_stamp: 'Electronic Seal',
    stamp_time: 'Stamp Time',
    entity_stamp_type: '[Entity Seal] Stamp Type',
    official_seal: 'Official Seal',
    contract_seal: 'Contract Seal',
    legal_representative_seal: 'Legal Representative Seal',
    financial_seal: 'Financial Seal',
    electron_stamp_type: '[Digital Seal] Stamp Type'
  },
  project: {
    project_code: 'Project Code',
    project_name: 'Project Name',
    project_mail: 'Project Mail',
    transfer_status: 'Transfer Status',
    handed: 'Transferred',
    unhanded: 'Not Transferred',
    transfer: 'Transfer',
    original_enterprise: 'Original Enterprise',
    transferred_enterprise: 'Transferred Enterprise',
    transferred_enterprise_user: 'Transferred  Enterprise Users',
    transfer_successful: 'Transfer Successful',
    sub_user: 'Sub-user',
    status: 'Status',
    license: 'License Key',
    failback_key: 'Failback Key',
    mail: 'Email',
    edit_project: 'Modify Project',
    create_project: 'Create Project',
    enabled: 'Enable',
    assign_sub_user: 'Assign Sub-User',
    select_sub_user: 'Sub-user',
    huawe_cloud_lic: 'Huawei Cloud Code',
    detail: 'Project Detail'
  },
  assign: {
    status_0: 'Pending Assignment',
    status_1: 'Assigned',
    status_8: 'Cancelled',
    status_9: 'Rejected',
    handed: 'Transferred',
    unhanded: 'Not Transferred',
    application_info: 'Application Information',
    create_assign: 'Create & Allocate',
    check_assign: 'Review & Allocation',
    license_statistics: 'Authorization Statistics',
    license_code: 'Authorization ID',
    project: 'Project',
    assigned_count: 'Assigned Count',
    license_start_at: 'License Effective Date',
    reissue: 'Reissue',
    create_assignment: 'Create Assignment',
    allocate_by_project: 'Assignment by Project',
    allocate_by_license: 'Assignment by License Key',
    license_key: 'License Key',
    select_project: 'Project',
    no_project: 'No project found',
    click_to_create: 'Click here to create',
    select_order: 'Order',
    recipient_mail: 'Authorized recipient email',
    authorization_method: 'Authorization Method',
    author_license: 'License Key Authorization',
    author_user: 'Product User Authorization',
    select_pro_user: 'Product Users',
    add_mail: 'Add Receiving Email',
    effective_immediately: 'Immediately',
    scheduled: 'Scheduled',
    select_effective_time: 'Select Effective Time',
    send_mail_type: 'Send Email Type',
    no_project_lic: 'No project corresponding to this license was found, please re-enter.',
    send_migraiton_mail: 'Send Migration Email',
    send_dr_failback_mail: 'Send DR email and Fallback email',
    send_dr_mail: 'Send DR Email',
    send_failbackr_mail: 'Send Fallback Email',
    detail: 'Assignment Detail',
    license_secret: 'License Secret',
    failback_secret: 'Failback Secret',
    generate_failback_secret: 'Generate Failback Secret',
    send_email: 'Send Emails',
    product_users: 'Product User',
    receive: 'Receive',
    mail: 'Email',
    mail_success: 'Send successful',
    mail_failed: 'Send failed',
    mail_sending: 'Sending',
    resend: 'Resend',
    custom_mail: 'Custom Email',
    send_custom_mail: 'Send Custom Email',
    issuance_status: 'Issuance Status',
    sub_issuance_status: 'Issuance Status',
    issuing: 'Issuing',
    issued_successfully: 'Issued Successfully',
    issued_failed: 'Issued Failed',
    project_assign_history: 'Project Assignment History',
    activated_count: 'Activated Count',
    inactive_count: 'Inactive Count',
    dr: 'DR',
    failback: 'Failback',
    authorized_count: 'Authorized Count',
    unauthorized_count: 'Unauthorized Count',
    reissue_tip: 'Sure to reissue?',
    reissue_title: 'Reissue',
    add_email: 'Add Email',
    confirm_to_reissue: 'Confirm to send',
    three_mail: 'You can add up to three recipient email addresses.',
    license_assign_failed: 'License issuance failed during the creation of the allocation authorization.',
    assignment: 'Assignment',
    selected_order: 'Selected Orders',
    no_order_find: 'Order not found',
    no_order_find_tip: 'The order needs to be approved before it can be selected.',
    scan_code: 'QR code scan',
    sub_user_apply: 'Sub-user Application',
    admin_create: 'Admin Creation',
    third_platform: 'Third-party Platform',
    International: 'International Test Application',
    apply: 'Application',
    success: 'Successful',
    application_creation_time: 'Application Creation Time',
    application_id: 'Application ID',
    application_source: 'Application Source',
    application_count: 'Application Count',
    assign: 'Assign',
    product_user_mail: 'Product User Email',
    create_application: 'Create Application',
    my_application: 'My Application',
    sub_user_select_order: 'Select Order',
    application_detail: 'Application Detail',
    project_apply_history: 'Project Application History',
    historical_order_allocation: 'Historical Order Allocation',
    apply_status: 'Application Status',
    assign_count: 'Assignment Count',
    user_apply_history: 'Application History',
    add_receving_mail: 'Please add receiving email.',
    issuse_mail: 'Assign Emails',
    type: 'Assign Type',
    no_project_product: 'The project has no associated orders. Please select a different project.',
    ressiue_order: 'Please select orders to reissue.',
    select_license_start_at: 'Please select license effective date.'
  },
  unit: {
    ge: ''
  }
}