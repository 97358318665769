<template>
  <!-- 语言切换组件 -->
  <el-dropdown popper-class="popper" trigger="click" @command="changeLanguage">
    <svg-icon icon-class="language" className="language" />
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item :disabled="language === 'zh-cn'" command="zh-cn">{{ t('lang.zh') }}</el-dropdown-item>
        <el-dropdown-item :disabled="language === 'en'" command="en">{{ t('lang.en') }}</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup>
import { ref, inject, onMounted } from 'vue'
import { getConfig } from '@/utils/api.js'
import store from '@/store'
const language = ref()
const t = inject('t')
// 切换语言
const changeLanguage = () => {
	language.value = localStorage.getItem('language') && localStorage.getItem('language').toLowerCase() !== 'zh-cn' ? 'zh-CN' : 'en'
  localStorage.setItem('language', language.value)
  location.reload()
}
// 获取系统语言
const getSystemLanguage = async () => {
  const res = await getConfig()
  if (!res.language) return
  if (res.language === 'zh') {
    localStorage.setItem('language', 'zh-CN')
  } else {
    localStorage.setItem('language', 'en')
  }
  language.value = localStorage.getItem('language').toLowerCase()
}
onMounted(() => {
  language.value = localStorage.getItem('language').toLowerCase()
  if (store.users) {
    getSystemLanguage()
  }
})
</script>

<style lang="scss" scoped>
:deep(.language) {
  cursor: pointer;
  height: 1.1em;
  width: 1.1em;
  font-size: 14px;
}
</style>