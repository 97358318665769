<template>
  <div>
    <!-- 授权下发状态 -->
    <div class="mail_res">
      <!-- 发送中 -->
      <div class="no_approved" v-if="issueStatus === 'running'">
        <el-icon>
          <WarningFilled />
        </el-icon>
        <span>{{ t('assign.issuing') }}</span>
      </div>
      <div class="finished" v-else-if="issueStatus === 'succeed'">
        <el-icon>
          <SuccessFilled />
        </el-icon>
        <span>{{ t('assign.issued_successfully') }}</span>
      </div>
      <!-- 下发失败 -->
      <div class="faild" style="cursor: pointer;" v-else-if="issueStatus === 'failed'" @click.stop="statusDetail">
        <el-icon>
          <WarningFilled />
        </el-icon>
        <span>{{ t('assign.issued_failed') }}</span>
      </div>
      <!-- 无下发 -->
      <div v-else>-</div>
      <!-- 重新下发 -->
      <el-button
        v-if="issueStatus && $userRole(['Enterprise'])"
        :disabled="issueStatus !== 'failed'"
        link
        type="primary"
        class="margin-l4"
        @click="resendIssue">{{ t('assign.reissue_title') }}<el-icon>
          <ArrowRight />
        </el-icon>
      </el-button>
    </div>
    <!-- 下发失败查看详情 -->
    <Faild ref="fail" />
  </div>
</template>

<script setup>
import { ElMessageBox } from 'element-plus'
import { computed, inject, ref, getCurrentInstance, nextTick } from 'vue'
import { AuthManagement } from '@/utils/api'
import Faild from './Faild'

const { proxy } = getCurrentInstance()
const fail = ref()
const t = inject('t')
const props = defineProps({
  detail: {
    type: Object,
    default: () => {}
  }
})
const issueStatus = computed(() => {
  return props.detail.task ? props.detail.task.status : ''
})

// 下发失败状态详情
const statusDetail = async () => {
  if (!!!proxy.$userRole(['Enterprise']) || !props.detail.id) return // 子用户不可查看下发错误详情
  await nextTick()
  fail.value.openWin(props.detail.id)
}
// 重发授权
const resendIssue = () => {
  if (!props.detail.task.uuid) return
  ElMessageBox.confirm(
    t('assign.reissue_tip'),
    t('assign.reissue_title'),
    {
      confirmButtonText: t('form.submit'),
      cancelButtonText: t('form.cancel'),
      type: 'warning',
    }
  ).then((action) => {
    if (action === 'confirm') {
      AuthManagement.AssignmentRetry(props.detail.task.uuid).then(res => {
        issueStatus.value = 'running'
      })
    }
  }).catch()
}
</script>