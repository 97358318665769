<template>
  <div class="platname" :style="sliderStyle">
    <img :src="logo" />
  </div>
  <el-menu
    :default-active="activeRoute"
    :active-text-color="menuActiveColor"
    :textColor="defaultTextColor"
    :backgroundColor="themeBg"
    :class="{ sales_charge: store.state.salesLoginId }"
    class="el-menu-vertical-demo"
    router>
    <template v-for="(item, index) in menuList" :key="index">
      <template v-if="!item.hidden && $userRole(item.meta.permission)">
        <el-sub-menu
          v-if="item.children && item.children.length > 1"
          :index="item.redirect">
          <template #title>
            <svg-icon :icon-class="item.meta.icon"></svg-icon>
            <span>{{ t(`routes.${item.name}`) || '' }}</span>
          </template>
          <template v-for="(items, key) in item.children">
            <el-menu-item
              v-if="$userRole(items.meta.permission) && !(items.name === 'assignment' && store.state.users.tags === 1)"
              :index="`${item.path}/${items.path}`"
              :key="key">
              <template #title>
                <span class="sub-title">{{ t(`routes.${items.name}`) || '' }}</span>
              </template>
            </el-menu-item>
          </template>
        </el-sub-menu>
        <el-menu-item v-else :index="item.path">
          <svg-icon :icon-class="item.meta.icon"></svg-icon>
          <template #title>
            <span>{{ t(`routes.${item.name}`) || '' }}</span>
          </template>
        </el-menu-item>
      </template>
    </template>
  </el-menu>
</template>

<script setup>
import { ref, onMounted, inject, watch, computed } from 'vue'
import { menuTheme } from '@/common/theme'
import { useRoute } from 'vue-router'
import { pageRoutes } from '@/router/route'
import store from '@/store'
const t = inject('t')
const props = defineProps({
  isCollapse: {
    type: Boolean,
    default: false
  },
  is_mobile: {
    type: Boolean,
    default: false
  }
})

const route = useRoute()
const themeBg = ref()// 菜单背景色
const defaultTextColor = menuTheme.menuDefaultColor // 默认导航文字颜色
const menuActiveColor = ref('') //导航选中文字颜色
const menuList = ref([])

// 侧边logo
const logo = computed(() => {
  const language = localStorage.getItem('language').toLowerCase()
  if (store.state.salesLoginId) { // 销售代管
    return require(`@/assets/menu_logo2_${language}.png`)
  }
  return require(`@/assets/menu_logo_${language}.png`)
})

// 侧边导航样式
const sliderStyle = computed(() => {
  if (store.state.salesLoginId) { // 销售代管
    return {
      'background-color': menuTheme.salesLogoBg,
      'border-color': menuTheme.salesLogoBg
    }
  }
  return {
    'background-color': menuTheme.white,
    'border-color': ''
  }
})
// 当前选中导航
const activeRoute = computed(() => {
  return route.path === '/dashboard' ? '/' : route.path
})

// 根据当前登录用户是否是销售代管，设置导航样式
watch(() => store.state.salesLoginId, val => {
  if (val) {
    themeBg.value = menuTheme.escrowColor
    menuActiveColor.value = menuTheme.escrowmenuActiveColor
  } else {
    themeBg.value = menuTheme.menuBgColor
    menuActiveColor.value = menuTheme.menuActiveColor
  }
}, { deep: true, immediate: true })

onMounted(() => {
  menuList.value = pageRoutes
})

</script>

<style>
.sub-title {
  margin-left: 4px
}
</style>