<template>
  <div>
    <!-- 发送邮箱 -->
    <div class="mail_status" v-for="(item, key) in mailList">
      <div>
        {{ key === 'project_mail' ? t('dashboard.project') : key === 'sub_user_mail' ? t('user.sub_user') : key === 'scan_mail' ? t('assign.product_users') : t('assign.receive') }} {{ t('assign.mail') }}: {{ item.address || '-' }}
      </div>
      <div v-if="$userRole(['Enterprise']) && item.status !== -1" class="flex_colum"
        >
        <!-- item.status -1 ：未分配 -->
        <!-- 邮箱发送状态 -->
        <!-- 发送成功 -->
        <div v-if="item.status === 1" class="finished">
          <el-icon>
            <SuccessFilled />
          </el-icon>
          <span>{{ t('assign.mail_success') }}</span>
        </div>
        <!-- 发送失败 -->
        <div v-if="item.status === 9" class="faild">
          <el-icon>
            <WarningFilled />
          </el-icon>
          <span>{{ t('assign.mail_failed') }}</span>
        </div>
        <!-- 发送中 -->
        <div v-if="item.status === 0" class="no_approved">
          <el-icon>
            <WarningFilled />
          </el-icon>
          <span>{{ t('assign.mail_sending') }}</span>
        </div>
        <!-- 重发、发送自定义邮箱 -->
        <el-button
          v-if="!mailType"
          type="primary"
          link 
          @click="resendMail(item, key)"
          class="no_approved resend_mail margin-l2">
          {{ mailType ? t('assign.send_custom_mail') : t('assign.resend') }}
          <el-icon>
            <ArrowRight />
          </el-icon>
        </el-button>
      </div>
    </div>
    <!-- 授权下发自定义邮箱 -->
    <div v-if="detail.status === 1 && !!mailType"><el-button type="primary" plain
      @click="resendMail">{{ t('assign.send_custom_mail') }}</el-button></div>
    <!-- 重发邮箱/自定义邮箱 -->
    <custem-mail ref="custem_mail" :detail="detail" @sendMailList="sendMailList" />
  </div>
</template>

<script setup>
import { AuthManagement } from '@/utils/api'
import { ref, inject, computed } from 'vue'
import CustemMail from './CustemMail'

const t = inject('t')
const custem_mail = ref()
const props = defineProps({
  detail: {
    type: Object,
    default: () => {}
  },
  type: {
    type: String,
    default: ''
  },
  mailType: {
    type: String,
    default: ''
  }
})
const mailList = computed(() => {
  return !props.mailType ? sendMail.value : sendCutemMail.value
})
// 查询发送邮箱接口
const sendMail = ref({}) // 发送邮箱
const sendCutemMail = ref([])
const task_data = ref({})
const sendMailList = async () => {
  let res
  sendCutemMail.value = []
  sendMail.value = {}
  task_data.value = {}
  if (props.type) { // 授权邮箱列表
    res = await AuthManagement.MailAssignmentStatus(props.detail.id)
  } else {
    res = await AuthManagement.MailAllocationStatus(props.detail.id)
  }
  for (const key in res.task_data) {
    if (key.includes('custom_mail')) {
      sendCutemMail.value.push(res.task_data[key])
    } else {
      sendMail.value[key] = res.task_data[key]
    }
  }
}
// 自定义邮箱渲染
const sendCustemMailList = () => {
  for (const key in task_data.value) {
    if (key.includes('custom_mail')) {
      sendCutemMail.value.push(task_data.value[key])
    }
  }
}
// 重发邮件
const resendMail = (item, mail_type) => {
  if (props.mailType === 'custem') {
    custem_mail.value.openWin({
      type: 'assignment',
      id: props.detail.id
    }, 'custem')
    return
  }
  custem_mail.value.openWin({
    type: props.type || 'allocation',
    id: props.detail.id,
    mail_type: mail_type,
    address: item.address
  }, 'resend')
}

defineExpose({
  sendMailList: sendMailList,
  sendCustemMailList: sendCustemMailList
})
</script>
<style lang="scss" scoped>
.flex_colum {
  display: flex;
  align-items: center;
  margin-left: 5px;
  div {
    display: flex;
    align-items: center;
  }
}
.mail_status {
  display: flex;
  align-items: center;
}
</style>