<template>
  <el-dialog
    v-model="winShow"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="t('order.select_origional_order')"
    @close="closeWin"
    width="900px">
    <!-- 搜索 -->
    <div class="select_bar" v-if="projectList.length">
      <div class="form_bar select_bar_form">
        <span class="margin-r2">{{ t('assign.select_project') }}: </span>
        <el-select v-model="searchData.select" clearable filterable @change="selectOrder" :loading="loadingProject"
          value-key="id">
          <el-option v-for="(item, index) in projectList" :label="item.name" :value="item" :key="index" />
        </el-select>
      </div>
      <div class="form_bar" v-if="searchData.input">
        <el-input v-model="searchData.input" disabled :placeholder="t('product.product_name')"></el-input>
      </div>
      <div class="form_bar" v-if="searchData.input1">
        <el-input v-model="searchData.input1" disabled :placeholder="t('product.product_code')"></el-input>
      </div>
    </div>
    <div class="margin-t4">
      <!-- 订单列表 -->
      <custem-table height="400" ref="table" :tableInfo="tableInfo" :tableLoading="tableLoading" @getList="getList" />
    </div>
    <!-- 订单详情 -->
    <DetailWin ref="detail_win" />
  </el-dialog>
</template>

<script setup>
import { ref, inject, reactive, getCurrentInstance, nextTick } from 'vue'// 表单、筛选框数据
import { AuthManagement } from '@/utils/api'
import DetailWin from '@/views/Authorization/Order/components/Detail'

const t = inject('t')
const winShow = ref(false)
const tableLoading = ref(false)
const loadingProject = ref(false)
const projectList = ref([]) // 项目列表
const emit = defineEmits(['setOrderInfo'])
const searchData = reactive({})
const enterprise_id = ref(null)
const table = ref()
const detail_win = ref()
const { proxy } = getCurrentInstance()
// 选择
const sureOrder = (data) => {
  emit('setOrderInfo', data)
  closeWin()
}
// 订单详情
const orderDetail = async (data) => {
  await nextTick()
  detail_win.value.openWin(data)
}
const tableInfo = reactive({
  header: [
    { // 订单编号
      key: 'code',
      label: t('order.order_code'),
      minWidth: '180px',
      custem: (data) => {
        return data.code || '-'
      }
    },
    { // 订单状态
      key: 'is_available',
      label: t('order.order_status'),
      minWidth: '140px',
      sortable: true,
      sortBy: (data) => {
        return data.is_available
      },
      hoverText: (data) => {
        return data.is_available ? `<p class="finished approved">${t('order.available')}</p>` : `<p class="faild approved">${data.unavailability_reason}</p>`
      },
      custem: (data) => {
        return data.is_available ? `<p class="finished approved">${t('order.available')}</p>` : `<p class="faild approved">${t('order.unavailable')}</p>`
      }
    },
    { // 产品名称
      key: 'id',
      label: t('product.product_name'),
      minWidth: '200px',
      custem: (data) => {
        return data.product ? data.product.name : '-'
      }
    },
    { // 使用周期
      key: 'use_period',
      label: t('order.order_cycle'),
      minWidth: '140px',
      sortable: true,
      sortBy: (data) => {
        return data.use_period
      },
      custem: (data) => {
        return data.use_period ? `${proxy.$numberToCurrencyNo(data.use_period)} ${Number(data.use_period) > 1 ? t('system.days') : t('system.days')}` : '-'
      }
    },
    { // 订单总数
      key: 'id',
      label: t('order.order_total'),
      minWidth: '140px',
      sortable: true,
      sortBy: (data) => {
        return data.purchased.hosts.value
      },
      custem: (data) => {
        return data.purchased ? `${proxy.$numberToCurrencyNo(data.purchased.hosts.value)} ${t('unit.ge')}` : '-'
      }
    },
    { // 当前可用
      key: 'id',
      label: t('order.can_use'),
      minWidth: '110px',
      sortable: true,
      sortBy: (data) => {
        return data.available.hosts.value
      },
      custem: (data) => {
        return data.is_available ? (data.available ? `${proxy.$numberToCurrencyNo(data.available.hosts.value)} ${t('unit.ge')}` : '0') : 0
      }
    },
    { // 分配到期时间
      key: 'expired_at',
      minWidth: '240px',
      label: t('order.ass_end_time'),
      sortable: true,
      sortBy: (data) => {
        return data.expired_at
      },
    },
    {
      label: t('table.action'),
      width: '120px',
      fixed: 'right',
      handleFun: [
        {
          name: t('form.select'),
          fn: sureOrder,
          disabled: (data) => {
            return !!!data.reissue
          }
        },
        {
          name: t('table.detail'),
          fn: orderDetail
        }
      ]
    }
  ]
})
const selectOrder = (data) => {
  // 如果没有数据，重置并返回
  if (!data) {
    getList();
    searchData.input = ''
    searchData.input1 = ''
    return
  }
  // 如果没有产品信息，直接返回
  const product = data.product
  if (!product) return
  table.value.resetCurrent() // 重置分页
  // 调用获取列表的函数
  getList({
    filter_product_id: product.id,
    filter_project_id: data.id
  })
  // 更新搜索数据
  searchData.input = product.name
  searchData.input1 = product.code
}
// 获取订单列表
const getList = async (searchData = {}) => {
  tableLoading.value = true
  try {
    const res = await AuthManagement.OrderList({
      ...searchData,
      filter_enterprise_id: enterprise_id.value,
      filter_category_id: 1001 // 只查询新购订单
    })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}
// 获取项目列表
const getProjectList = async (id) => {
  loadingProject.value = true
  try {
    const res = await AuthManagement.ProjectList({
      filter_enterprise_id: id,
      is_paginate: false,
      filter_effectuate: true
    })
    projectList.value = res.items
    loadingProject.value = false
  } catch (error) {
    loadingProject.value = false
  }
}
const closeWin = () => {
  winShow.value = false
  enterprise_id.value = ''
  searchData.input = ''
  searchData.input1 = ''
  searchData.select = ''
}
// 打开弹框
const openWin = (val) => {
  winShow.value = true
  enterprise_id.value = val
  if (table.value) table.value.resetCurrent() // 重置分页
  getList()
  getProjectList(val)
}
defineExpose({
  openWin
})
</script>
<style lang="scss" scoped>
.select_bar {
  display: flex;
  align-items: center;
}

:deep(.el-table__body-wrapper) {
  max-height: 400px;
  overflow-y: scroll;
}

.form_bar {
  width: 150px !important;
}

.select_bar_form {
  width: 240px !important;
  display: flex;
  align-items: center;
}

.select-order-table {
  height: 500px;
}
</style>