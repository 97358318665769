
import Layout from '@/views/Layout'
// 默认路由
export const defaultRoutes = [
  {
    path: '/:pathMatch(.*)',
    name: 'error',
    meta: {
      permission: [],
      title: 'error'
    },
    component: () => import('@/views/Error')
  },
  {
    name: 'login',
    path: '/login',
    meta: {
      permission: []
    },
    component: () => import('@/views/Login')
  }
]

// 页面路由
export const pageRoutes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'dashboard',
    component: Layout,
    meta: {
      icon: 'dashboard',
      permission: ['SuperAdmin', 'GeneralAdmin', 'SalesMan', 'Enterprise', 'Subordinate']
    },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        meta: {
          permission: ['SuperAdmin', 'GeneralAdmin', 'SalesMan', 'Enterprise', 'Subordinate']
        },
        component: () => import('@/views/dashboard'),
      }
    ]
  },
  { // 用户管理
    path: '/user',
    name: 'user',
    redirect: '/user/general',
    component: Layout,
    meta: {
      icon: 'UserFilled',
      permission: ['SuperAdmin', 'GeneralAdmin', 'SalesMan', 'Enterprise']
    },
    children: [
      // {
      //   path: '/user/default_user',
      //   name: '内部用户',
      //   meta:{
      //     icon:'',
      //     permission:['SuperAdmin'],
      //     title:'内部用户'
      //   },
      //   component:() => import('@/views/Users/DefaultUser'),
      // },
      { // 运维管理
        path: 'general',
        name: 'general',
        meta: {
          permission: ['SuperAdmin']
        },
        component: () => import('@/views/Users/General')
      },
      { // 销售管理
        path: 'salesman',
        name: 'salesman',
        meta: {
          permission: ['SuperAdmin', 'GeneralAdmin']
        },
        component: () => import('@/views/Users/SalesMan')
      },
      { // 企业用户管理
        path: 'enterprise_user',
        name: 'enterprise_user',
        meta: {
          permission: ['SuperAdmin', 'GeneralAdmin', 'SalesMan']
        },
        component: () => import('@/views/Users/Enterprise')
      },
      { // 企业子用户
        path: 'subuser',
        name: 'subuser',
        meta: {
          permission: ['Enterprise']
        },
        component: () => import('@/views/Users/SubUser')
      },
      { // 三方授权
        path: 'third_oauth',
        name: 'third_oauth',
        meta: {
          permission: ['SuperAdmin', 'GeneralAdmin']
        },
        component: () => import('@/views/Users/ThirdOauth')
      }
    ]
  },
  { // 企业管理
    path: '/enterprise',
    redirect: '/enterprise',
    name: 'enterprise',
    component: Layout,
    meta: {
      icon: 'enterprise_management',
      permission: ['SuperAdmin', 'GeneralAdmin', 'SalesMan']
    },
    children: [
      {
        path: '/enterprise',
        meta: {
          permission: ['SuperAdmin', 'GeneralAdmin', 'SalesMan']
        },
        component: () => import('@/views/Enterprise')
      }
    ]
  },
  { // 产品管理
    path: '/product',
    name: 'product',
    redirect: '/enterprise/product',
    component: Layout,
    meta: {
      icon: 'product_management',
      permission: ['SuperAdmin', 'GeneralAdmin', 'SalesMan']
    },
    children: [
      { // 产品管理
        path: 'product',
        name: 'product',
        meta: {
          permission: ['SuperAdmin', 'GeneralAdmin']
        },
        component: () => import('@/views/Products/Product')
      },
      { // 企业产品
        path: 'enterprise_product',
        name: 'enterprise_product',
        meta: {
          permission: ['SuperAdmin', 'GeneralAdmin', 'SalesMan']
        },
        component: () => import('@/views/Products/EnterpriseProduct')
      },
      { // 销售产品
        path: 'sales_product',
        name: 'sales_product',
        meta: {
          permission: ['SuperAdmin', 'GeneralAdmin']
        },
        component: () => import('@/views/Products/SalesProduct')
      }
    ]
  },
  { // 试用项目
    path: '/test_project',
    redirect: '/test_project',
    name: 'test_project',
    component: Layout,
    meta: {
      icon: 'test_management',
      permission: ['SuperAdmin', 'GeneralAdmin']
    },
    children: [
      {
        path: '/test_project',
        meta: {
          permission: ['SuperAdmin', 'GeneralAdmin']
        },
        component: () => import('@/views/TestProject')
      }
    ]
  },
  { // 授权管理
    path: '/authorization',
    redirect: '/authorization/project',
    name: 'authorization',
    component: Layout,
    meta: {
      icon: 'assign_management',
      permission: ['SuperAdmin', 'GeneralAdmin', 'SalesMan', 'Subordinate']
    },
    children: [
      { // 项目管理
        path: 'project',
        name: 'project',
        meta: {
          permission: ['SuperAdmin', 'GeneralAdmin', 'Enterprise']
        },
        component: () => import('@/views/Authorization/Project')
      },
      { // 订单管理
        path: 'order',
        name: 'order',
        meta: {
          permission: ['SuperAdmin', 'GeneralAdmin', 'SalesMan', 'Enterprise']
        },
        component: () => import('@/views/Authorization/Order')
      },
      { // 授权分配
        path: 'assignment',
        name: 'assignment',
        meta: {
          permission: ['Enterprise']
        },
        component: () => import('@/views/Authorization/Assignment')
      },
      { // 申请管理
        path: 'application',
        name: 'application',
        meta: {
          permission: ['Enterprise', 'Subordinate']
        },
        component: () => import('@/views/Authorization/Application')
      }
    ]
  },
  { // 项目管理 - 代管用户
    path: '/behalf_project',
    redirect: '/behalf_project',
    name: 'behalf_project',
    component: Layout,
    meta: {
      icon: 'project_management',
      permission: ['Enterprise']
    },
    children: [
      { // 项目管理
        path: '/behalf_project',
        meta: {
          permission: ['Enterprise']
        },
        component: () => import('@/views/Authorization/Project')
      }
    ]
  },
  { // 订单管理 - 代管
    path: '/behalf_order',
    redirect: '/behalf_order',
    name: 'behalf_order',
    component: Layout,
    meta: {
      icon: 'order_management',
      permission: ['Enterprise']
    },
    children: [
      {
        path: '/behalf_order',
        meta: {
          permission: ['Enterprise']
        },
        component: () => import('@/views/Authorization/Order')
      }
    ]
  },
  { // 授权管理 - 代管
    path: '/behalf_auth',
    redirect: '/behalf_auth',
    name: 'behalf_auth',
    component: Layout,
    meta: {
      icon: 'assign_management',
      permission: ['Enterprise']
    },
    children: [
      {
        path: '/behalf_auth',
        meta: {
          permission: ['Enterprise']
        },
        component: () => import('@/views/Authorization/Auth')
      }
    ]
  },
  // {
  //   path: '/trial',
  //   name: '试用管理',
  //   component: Layout,
  //   meta: {
  //     icon: 'WalletFilled',
  //     permission: [],
  //     title: '试用管理'
  //   },
  //   children: [
  //     {
  //       path: '/trial/develop_license',
  //       name: '研发授权',
  //       meta: {
  //         icon: '',
  //         permission: [],
  //         title: '研发授权'
  //       },
  //       component: () => import('@/views/Trial/DevelopLicense')
  //     },
  //     {
  //       path: '/trial/trial_order',
  //       name: '试用申请',
  //       meta: {
  //         icon: '',
  //         permission: [],
  //         title: '试用申请'
  //       },
  //       component: () => import('@/views/Trial/TrialOrder')
  //     }
  //   ]
  // },
  { // 审计管理
    path: '/audit',
    redirect: '/audit',
    name: 'audit',
    component: Layout,
    meta: {
      icon: 'audit_management',
      permission: ['SuperAdmin']
    },
    children: [
      {
        path: '/audit',
        meta: {
          icon: 'WarningFilled',
          permission: ['SuperAdmin']
        },
        component: () => import('@/views/Audit')
      }
    ]
  },
  { // 个人信息
    path: '/userinfo',
    component: Layout,
    redirect: '/userinfo',
    hidden: true,
    meta: {
      permission: ['SuperAdmin', 'GeneralAdmin', 'SalesMan', 'Enterprise', 'Subordinate']
    },
    children: [
      {
        path: '/userinfo',
        name: 'userinfo',
        meta: {
          hidden: true,
          permission: ['SuperAdmin', 'GeneralAdmin', 'SalesMan', 'Enterprise', 'Subordinate']
        },
        component: () => import('@/views/UserInfo')
      }
    ]
  },
  { // 站内通知
    path: '/message',
    name: 'message',
    component: Layout,
    redirect: '/message',
    hidden: true,
    meta: {
      permission: ['SuperAdmin', 'GeneralAdmin', 'SalesMan', 'Enterprise', 'Subordinate']
    },
    children: [
      {
        path: '/message',
        meta: {
          hidden: true,
          permission: ['SuperAdmin', 'GeneralAdmin', 'SalesMan', 'Enterprise', 'Subordinate']
        },
        component: () => import('@/views/Message')
      }
    ]
  }
]

