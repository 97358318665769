<!-- 详情 -->
<template>
  <el-descriptions
    class="margin-top"
    :column="2"
    v-loading="loading"
    border>
    <template v-for="item in descriptions">
      <el-descriptions-item
        v-if="!item.hidden"
        :span="item.span || 1">
        <template #label>
          <span class="desc_label">{{ item.label || '-' }}</span>
        </template>
        <div v-if="item.type && item.type === 'slot'">
          <slot :name="item.prop"></slot>
        </div>
        <div v-else>
          <div v-html="item.value"></div>
        </div>
      </el-descriptions-item>
    </template>
  </el-descriptions>
</template>

<script setup>
import { watch } from 'vue'
import { formateDate } from '@/utils/format'
const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  // 默认显示几列
  column: {
    type: Number,
    default: 2
  },
  // 数据列表
  descriptions: {
    type: Array,
    default: () => []
  }
})
watch(() => props.descriptions, val => {
  if (val.length) formateDate(val)
}, { deep: true })
</script>

<style lang="scss" scoped>
:deep(.el-descriptions__body .el-descriptions__table.is-bordered .el-descriptions__cell) {
  width: 185px !important;
}
</style>