import {
  createRouter,
  createWebHistory
} from "vue-router"
import { defaultRoutes, pageRoutes } from './route'
import store from '@/store'
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...pageRoutes, ...defaultRoutes]
})

router.beforeEach(async (to, from, next) => {
  if (!!!store.state.authorization) {
    // 未登录
    if (to.path !== '/login') {
      next({ path: '/login' })
    } else {
      next()
    }
  } else {
    // 已登录
    let role = store.state.role
    if (!!!store.state.salesLoginId) { // 非销售代管
      // 权限判断
      if (to.meta.permission.length) {
        if (role && to.meta.permission.includes(role)) {
          next()
        } else {
          next({ path: '/login' })
        }
      } else {
        next()
      }
    } else {
      next()
    }
  }
})

export default router