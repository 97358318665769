<template>
  <el-dialog
    v-model="winShow"
    width="800px"
    :close-on-click-modal="false"
    :title="send_type === 'custem' ? t('assign.send_custom_mail') : t('assign.resend')"
    :close-on-press-escape="false"
    @close="onClose">
    <el-form :model="emailData" :rules="rules" ref="form" label-width="120px" label-position="right">
      <template v-if="send_type === 'custem'">
        <el-form-item v-for="(item, index) in formEmailItems" :key="index" :label="index === 0 ? t('assign.add_email') : ''"
        :prop="item.prop" required>
        <div class="add-mail">
          <el-input v-model="emailData[item.prop]" :placeholder="t('form.inputPlaceholder')" />
          <span @click="delMail(item.prop, index)" v-if="index">{{ t('form.delete') }}</span>
        </div>
        </el-form-item>
        <el-form-item>
          <el-button @click="addMail" type="primary" plain>＋{{ t('assign.add_email') }}</el-button>
        </el-form-item>
      </template>
      <el-form-item v-else :label="t('project.mail')">
        <div class="add-mail">
          <el-input v-model="emailData.mail0" disabled :placeholder="t('form.inputPlaceholder')" />
        </div>
      </el-form-item>
      <!-- 发送邮件类型 -->
      <el-form-item :label="t('assign.send_mail_type')" required>
        <el-radio-group v-model="send_flag">
          <el-radio value="standard" size="large">{{ prodictType === 1001 ? t('assign.send_migraiton_mail') : t('assign.send_dr_failback_mail') }}</el-radio>
          <el-radio v-if="prodictType !== 1001" value="dr" size="large">{{ t('assign.send_dr_mail') }}</el-radio>
          <el-radio v-if="prodictType !== 1001" value="failback" size="large">{{ t('assign.send_failbackr_mail') }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="onClose">{{ t('form.cancel') }}</el-button>
      <el-button :loading="sendLoading" type="primary" @click="send_type === 'custem' ? sendCustemMail() : resendMail()">
        {{ send_type === 'custem' ? t('assign.send_email') : t('assign.confirm_to_reissue') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, nextTick, inject, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { AuthManagement } from '@/utils/api'

const t = inject('t')
const winShow = ref(false)
const form = ref()
const send_type = ref('custem')
const send_flag = ref('standard')
const emit = defineEmits(['sendMailList'])
const props = defineProps({
  detail: {
    type: Object,
    default: () => {}
  }
})
// 发送邮件类型
const prodictType = computed(() => {
  return props.detail.project.product.category.id
})
const formEmailItems = ref([
  {
    prop: 'mail0'
  }
])
const emailData = ref({
  mail0: ''
})
const rules = ref({
  mail0: [{ required: true, message: t('form.emptyErrMsg'), trigger: "blur" },
  { required: true, message: t('form.error_mail'), trigger: "blur", type: 'email' }]
})
// 继续添加邮箱
const addMail = () => {
  if (formEmailItems.value.length === 3) {
    ElMessage.warning(t('assign.three_mail'))
    return
  }
  const newItem = { prop: `mail${formEmailItems.value.length}` }
  formEmailItems.value.push(newItem);
  // 更新校验规则
  rules.value[newItem.prop] = [
    { required: true, message: t('form.emptyErrMsg'), trigger: "blur" },
    { required: true, message: t('form.error_mail'), trigger: "blur", type: 'email' }
  ]
}

// 发送自定义邮箱
const sendLoading = ref(false)
const sendCustemMail = () => {
  form.value.validate(async valid => {
    if (!valid) return
    sendLoading.value = true
    // 取值并去重
    let values = Array.from(new Set(Object.values(emailData.value)))
    let arr = []
    values.forEach(item => {
      item = {
        type: sendData.value.type,
        address: item,
        id: sendData.value.id,
        send_flag: send_flag.value
      }
      arr.push(
        // 新增
        AuthManagement.MailCustem(item).then(res => { })
      )
    })
    await nextTick()
    try {
      await Promise.all(arr)
      ElMessage.success(t('assign.mail_success'))
      onClose()
      emit('sendMailList')
      sendLoading.value = false
    } catch (error) {
      sendLoading.value = false
    }
  })
}

// 重发邮箱
const resendMail = async () => {
  sendLoading.value = true
  try {
    await AuthManagement.MailCreate({
      type: sendData.value.type,
      id: sendData.value.id,
      mail_type: sendData.value.mail_type,
      address: sendData.value.address,
      send_flag: send_flag.value
    })
    ElMessage.success(t('assign.mail_success'))
    onClose()
    emit('sendMailList')
    sendLoading.value = false
  } catch (error) {
    sendLoading.value = false
  }
}

// 删除绑定邮箱
const delMail = (item, index) => {
  delete emailData.value[item]
  formEmailItems.value.splice(index, 1)
}

// 打开弹框
const sendData = ref({})
const openWin = (data = {}, mail_type = 'custem') => {
  winShow.value = true
  send_type.value = mail_type
  sendData.value = data
  if (data.address) emailData.value.mail0 = data.address
}

// 关闭弹框
const onClose = () => {
  formEmailItems.value = [
    {
      prop: 'mail0'
    }
  ]
  emailData.value = {
    mail0: ''
  }
  rules.value = {
    mail0: [{ required: true, message: t('form.emptyErrMsg'), trigger: "blur" },
    { required: true, message: t('form.error_mail'), trigger: "blur", type: 'email' }]
  }
  form.value.resetFields()
  sendData.value = {}
  send_flag.value = 'standard'
  sendLoading.value = false
  winShow.value = false
}
defineExpose({
  openWin: openWin
})
</script>

<style lang="scss" scoped>
.add-mail {
  display: flex;
  align-items: center;
  span {
    cursor: pointer;
    font-size: 12px;
    margin-left: 4px;
    color: var(--el-color-primary);
    min-width: 40px;
  }
}
</style>