import http from "./http"

const HttpGet = async (url, params) => {
  return await http.get(url, {
    params: params
  }).then(resp => {
    if (resp && resp.status === 200) {
      return Promise.resolve(resp.data)
    } else {
      return Promise.reject(resp)
    }
  })
}

const HttpPost = async (url, data) => {
  return await http.post(url, data).then(resp => {
    if (resp.status === 200) {
      return Promise.resolve(resp.data)
    } else {
      return Promise.reject(resp)
    }
  })
}

const HttpDelete = async (url) => {
  return await http.delete(url).then(resp => {
    if (resp.status === 200) {
      return Promise.resolve(resp.data)
    } else {
      return Promise.reject(resp)
    }
  })
}

/* ======================== 用户管理 ======================== */
export const userManagement = {
  // 用户列表
  roleUserList: async (params = {}) => {
    return await HttpGet('/v1/user/list', params)
  },
  // 检测用户
  checkUser: async (params = {}) => {
    return await HttpPost('/v1/user/check', params)
  },
  // 新增用户
  roleUserCreate: async (params = {}) => {
    return await HttpPost('/v1/user', params)
  },
  // 用户详情
  roleUserDetail: async (id) => {
    return await HttpGet('/v1/user/' + id)
  },
  // 用户所属销售列表
  roleUserSalesList: async (id) => {
    return await HttpGet(`/v1/user_sale/list?filter_user_id=${id}`)
  },

  // 更新用户
  roleUserUpdate: async (id, data) => {
    return await HttpPost('/v1/user/' + id, data)
  },
  // 绑定钉钉
  roleUserBind: async (id) => {
    return await HttpGet('/v1/user_oauth/relation?oauth=dingtalk&query_key=phone&user_id=' + id)
  },
  // 删除用户
  roleUserDelete: async (id) => {
    return await HttpDelete('/v1/user/' + id)
  },
  // 通过钉钉authCode获取用户信息
  roleDetailByDingTalk: async (authCode) => {
    return await HttpGet(`/v1/oauth/login/dingtalk?authCode=${authCode}`)
  },
  // 三方认证管理
  userOauth: async (params) => {
    return await HttpGet(`/v1/user_oauth/list`, params)
  },
  // 删除三方认证
  userOauthDelete: async (id) => {
    return await HttpDelete(`/v1/user_oauth/${id}`)
  },
  productUser: async (product_id, params) => {
    return await HttpGet(`/v1/product/${product_id}/users`, params)
  },
}

/* ======================== 企业管理 ======================== */

export const enterpriseManagement = {
  // 企业列表
  EnterpriseList: async (params = {}) => {
    return await HttpGet('/v1/enterprise/list', params)
  },
  // 企业列表
  checkEnterprise: async (params = {}) => {
    return await HttpPost('/v1/enterprise/check', params)
  },
  // 企业列表
  enterpriseSale: async (params = {}) => {
    return await HttpGet('/v1/enterprise_sale/list', params)
  },
  // 新增企业
  EnterpriseCreate: async (row) => {
    return await HttpPost('/v1/enterprise', row)
  },
  // 企业详情
  EnterpriseDetail: async (id) => {
    return await HttpGet(`/v1/enterprise/${id}`)
  },
  // 企业编辑
  EnterpriseUpdate: async (id, row) => {
    return await HttpPost(`/v1/enterprise/${id}`, row)
  },
  // 删除企业
  EnterpriseDelete: async (id) => {
    return await HttpDelete(`/v1/enterprise/${id}`)
  }
}


/* ======================== 产品管理 ======================== */

export const productManagement = {
  // 产品列表
  ProductList: async (params = {}) => {
    return await HttpGet('/v1/product/list', params)
  },
  // 产品大类列表
  ProductCategoryList: async (params = {}) => {
    return await HttpGet(`/v1/product_deployment_category/list`, params)
  },
  // 产品类型列表
  ProductDeploymentList: async (params = {}) => {
    return await HttpGet(`/v1/product/deployment/list`, params)
  },
  // 新增产品
  ProductCreate: async (params = {}) => {
    return await HttpPost('/v1/product', params)
  },
  // 产品详情
  ProductDetail: async (id) => {
    return await HttpGet(`/v1/product/${id}`)
  },
  // 产品编辑
  ProductUpdate: async (id, data) => {
    return await HttpPost(`/v1/product/${id}`, data)
  },
  // 产品删除
  ProductDelete: async (id) => {
    return await HttpDelete(`/v1/product/${id}`)
  },
  // 销售产品列表
  SalesProductList: async (params = {}) => {
    return await HttpGet(`/v1/user_product/list`, params)
  },
  // 新增销售产品
  SalesProductCreate: async (params = {}) => {
    return await HttpPost(`/v1/user_product`, params)
  },
  // 销售产品删除
  SalesProductDelete: async (id) => {
    return await HttpDelete(`/v1/user_product/${id}`)
  },
  // 企业产品列表
  EnterpriseProduct: async (row) => {
    return await HttpGet(`/v1/enterprise_product/list`, row)
  },
  // 新增企业产品
  EnterpriseProductCreate: async (row) => {
    return await HttpPost(`/v1/enterprise_product`, row)
  },
  // 删除企业产品
  EnterpriseProductDelete: async (id) => {
    return await HttpDelete(`/v1/enterprise_product/${id}`)
  },
  // 产品授权列表
  ProductLicenseList: async (params = {}) => {
    return await HttpGet('/v1/product/license/list', params)
  },
  // 删除产品授权
  ProductLicenseDelete: async (id) => {
    return await HttpDelete(`/v1/product/license/${id}`)
  },
  // 创建产品授权
  ProductLicenseCreate: async (data = {}) => {
    return await HttpPost(`/v1/product/license`, data)
  },
  // 编辑产品授权
  ProductLicenseUpdate: async (id, data = {}) => {
    return await HttpPost(`/v1/product/license/${id}`, data)
  },
  // 产品授权详情
  ProductLicenseDetail: async (id) => {
    return await HttpGet(`/v1/product/license/${id}`)
  }
}

/* ======================== 授权管理 ======================== */

export const AuthManagement = {
  /* ====== 项目管理 ====== */
  // 项目列表
  ProjectList: async (row) => {
    return await HttpGet(`/v1/project/list`, row)
  },
  // 绑定关联
  OrderProjectCreate: async (row) => {
    return await HttpPost(`/v1/order_project`, row)
  },
  // 删除关联
  OrderProjectDelete: async (id) => {
    return await HttpDelete(`/v1/order_project/${id}`)
  },
  // 订单关联项目列表
  OrderProjectList: async (row) => {
    return await HttpGet(`/v1/order_project/list`, row)
  },
  // 项目新增
  ProjectCreate: async (row) => {
    return await HttpPost(`/v1/project`, row)
  },
  // 创建授权选择订单列表
  OrderAssignmentList: async (row) => {
    return await HttpGet(`/v1/order/assignment/list`, row)
  },
  // 项目详情
  ProjectDetail: async (id) => {
    return await HttpGet(`/v1/project/${id}`)
  },
  // 项目更新
  ProjectUpdate: async (id, data) => {
    return await HttpPost(`/v1/project/${id}`, data)
  },
  // 项目移交
  ProjectRelocation: async (id, data) => {
    return await HttpPost(`/v1/project/relocation/${id}`, data)
  },
  // 项目删除
  ProjectDelete: async (id) => {
    return await HttpDelete(`/v1/project/${id}`)
  },
  // 查询产品注册码
  ProjectLicKey: async (id, saas_name = '') => {
    return await HttpGet(`/v1/product/${id}/user/${saas_name}`)
  },
  /* ====== 订单管理 ====== */
  // 订单列表
  OrderList: async (row) => {
    return await HttpGet(`/v1/order/list`, row)
  },
  // 订单总数，license总数
  OrderAmount: async (row) => {
    return await HttpGet(`/v1/order/amount`, row)
  },
  // 订单新增
  OrderCreate: async (row) => {
    return await HttpPost(`/v1/order`, row)
  },
  // 订单编辑
  OrderUpdate: async (id, row) => {
    return await HttpPost(`/v1/order/${id}`, row)
  },
  // 订单详情
  OrderDetail: async (id) => {
    return await HttpGet(`/v1/order/${id}`)
  },
  // 订单删除
  OrderDelete: async (id) => {
    return await HttpDelete(`/v1/order/${id}`)
  },
  /* ====== 授权分配 ====== */
  // 授权分配列表
  AssignmentList: async (row) => {
    return await HttpGet(`/v1/assignment/list`, row)
  },
  // 授权分配新增
  AssignmentCreate: async (row) => {
    return await HttpPost(`/v1/assignment`, row)
  },
  // 重新下发授权
  AssignmentRetry: async (id) => {
    return await HttpPost(`/v1/task/${id}/retry`)
  },
  // 授权分配详情
  AssignmentDetail: async (id) => {
    return await HttpGet(`/v1/assignment/${id}`)
  },
  // 授权分配更新
  AssignmentUpdate: async (id, data) => {
    return await HttpPost(`/v1/assignment/${id}`, data)
  },
  // 授权分配删除
  AssignmentDelete: async (id) => {
    return await HttpDelete(`/v1/assignment/${id}`)
  },
  // 授权统计
  AllocationAssignmentCount: async (row) => {
    return await HttpGet(`/v1/allocation_assignment/count`, row)
  },
  // 发送邮箱
  MailCreate: async (data) => {
    return await HttpPost('/v1/mail', data)
  },
  // 发送自定义邮箱
  MailCustem: async (data) => {
    return await HttpPost('/v1/mail/custom', data)
  },
  // 查询邮箱发送状态
  MailStatus: async (id) => {
    return await HttpGet(`/v1/mail/${id}`)
  },
  // 查询申请邮箱发送状态
  MailAllocationStatus: async (id) => {
    return await HttpGet(`/v1/allocation/mail/${id}`)
  },
  // 查询分配邮箱发送状态
  MailAssignmentStatus: async (id) => {
    return await HttpGet(`/v1/assignment/mail/${id}`)
  },
  /* ====== 申请管理 ====== */
  // 申请列表
  ApplicationList: async (params = {}) => {
    return await HttpGet('/v1/allocation/list', params)
  },
  // 申请创建
  ApplicationCreate: async (params = {}) => {
    return await HttpPost('/v1/allocation', params)
  },
  // 删除申请
  ApplicationDelete: async (id) => {
    return await HttpDelete(`/v1/allocation/${id}`)
  },
  // 下发申请
  ApplicationIssue: async (id, datas) => {
    return await HttpPost(`/v1/allocation/approve/${id}`, datas)
  },
  ApplicationDelete: async (id) => {
    return await HttpDelete(`/v1/allocation/${id}`)
  },
  // 申请详情
  ApplicationDetail: async (id) => {
    return await HttpGet(`/v1/allocation/${id}`)
  },
  // 申请编辑
  ApplicationUpdate: async (id, params = {}) => {
    return await HttpPost(`/v1/allocation/${id}`, params)
  },
  // 查询分配历史
  AllocationAssignmentList: async (params = {}) => {
    return await HttpGet(`/v1/allocation_assignment/list`, params)
  },
  // 新增分配历史
  AllocationAssignment: async (params = {}) => {
    return await HttpPost(`/v1/allocation_assignment`, params)
  },
  // 新增分配历史
  SetFailBack: async (params = {}) => {
    return await HttpPost(`/v1/assignment/fail_back`, params)
  },
  // 获取已授权数量和未授权数量字段
  getSaasLicense: async (assignment_id = null) => {
    return await HttpGet(`/v1/assignment/saas_license/${assignment_id}`)
  }
}

/* ======================== 试用管理 ======================== */
export const TrialManagement = {
  /* ====== 研发授权 ====== */
  // 研发授权列表
  DevelopLicenseList: async (row) => {
    return await HttpGet(`/v1/develop_license/list`, row)
  },
  // 研发授权删除
  DevelopLicenseDelete: async (id) => {
    return await HttpDelete(`/v1/develop_license/${id}`)
  },
  /* ====== 试用订单 ====== */
  // 试用订单列表
  TrialOrderList: async (params = {}) => {
    return await HttpGet('/v1/application/list', params)
  },
  // 试用订单新增
  TrialOrderCreate: async (params = {}) => {
    return await HttpPost('/v1/application', params)
  },
  // 试用订单详情
  TrialOrderDetail: async (id) => {
    return await HttpGet('/v1/application/' + id)
  },
  // 试用订单编辑
  TrialOrderUpdate: async (id, status) => {
    return await HttpPost(`/v1/application/${id}`, status)
  }
}

/* ======================== 审计管理 ======================== */
export const AuditManagement = {
  // 审计管理列表
  AuditList: async (params = {}) => {
    return await HttpGet(`/v1/audit/list`, params)
  }
}

/* ======================== 角色管理 ======================== */
export const RoleManagement = {
  // 角色列表
  RoleList: async (params = {}) => {
    return await HttpGet(`/v1/role/list`, params)
  },
  // 角色编辑
  RoleUpdate: async (id, row = {}) => {
    return await HttpPost(`/v1/role/${id}`, row)
  },
  // 角色新增
  RoleCreate: async (params = {}) => {
    return await HttpPost(`/v1/role`, params)
  },
  // 角色详情
  RoleDetail: async (id = {}) => {
    return await HttpGet(`/v1/role/${id}`)
  },
  // 角色删除
  RoleDelete: async (id) => {
    return await HttpDelete(`/v1/role/${id}`)
  }
}

/* ======================== 用户中心 ======================== */
export const Personal = {
  // 修改密码
  PasswordUpdate: async (row) => {
    return await HttpPost(`/v1/auth/password`, row)
  },
  // 更新用户信息
  UserUpdate: async (id, data) => {
    return await HttpPost('/v1/user/' + id, data)
  },
  // 用户详情
  UserDetail: async (id) => {
    return await HttpGet('/v1/user/' + id)
  }
}
//登录
export const Login = async (row) => {
  return await HttpPost('/v1/auth/login', row)
}
// 修改用户信息
export const updateConfig = async (row) => {
  return await HttpPost('/v1/user/update_config', row)
}
// 获取用户信息
export const getConfig = async () => {
  return await HttpGet('/v1/user/config')
}
//通过id登录
export const LoginByID = async (id) => {
  return await HttpPost(`/v1/auth/login/user/${id}`)
}
//试用钉钉认证
export const LoginByDingTalk = async (uri) => {
  return await HttpGet(`/v1/oauth/jump/dingtalk?uri=${uri}`)
}
//系统通知接口
export const Notification = async (params) => {
  return await HttpGet(`/v1/notification/list`, params)
}
// 删除系统通知接口
export const notificationDelete = async (params) => {
  return await HttpPost(`/v1/notification/delete/all`, params)
}
// 系统通知已读
export const notificationRead = async (params) => {
  return await HttpPost(`/v1/notification/update/all`, params)
}
