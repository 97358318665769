<template>
  <el-dialog
    v-model="winShow"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="t('system.changePwd')"
    width="900px">
    <div class="form">
      <!-- 表单 -->
      <custem-form
        ref="form"
        :loading="formLoading"
        :formData="formData"
        :formRules="formRules"
      />
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          :disabled="formLoading"
          :loading="submitLoading"
          type="primary"
          @click="submit">{{ t('form.submit') }}</el-button>
        <el-button @click="closeWin">{{ t('form.cancel') }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, inject, reactive } from 'vue'
import { rules } from '@/utils/rules'
import { Personal } from '@/utils/api'
import { ElMessage } from 'element-plus'

const t = inject('t')
const formLoading = ref(false)
const winShow = ref(false)
const submitLoading = ref(false)
const form = ref()
const formRules = reactive({
  password: rules,
  password1: rules,
  password2: [
    { required: true },
    { validator: (rule, modelVal, callback) => {
      if (!!!modelVal.value) {
        callback(new Error(t('system.new_pwd_again')))
      } else if (modelVal.value !== formData.password1.value) {
        callback(new Error(t('system.not_right')))
      } else {
        callback()
      }
    }}
  ]
})
const formData = reactive({
  password: { // 原密码
    label: t('system.old_pwd'),
    type: 'password'
  },
  password1: { // 新密码
    label: t('system.old_pwd'),
    type: 'password'
  },
  password2: { // 再次输入新密码
    label: t('system.new_pwd_again'),
    type: 'password',
    prop: 'password2'
  }
})

// 提交
const submit = async () => {
  const res = await form.value.submitForm()
  if (!res) return
  submitLoading.value = true
  try {
    const postData = {}
    for (const key in formData) {
      postData[key] = formData[key].value
    }
    await Personal.PasswordUpdate(postData)
    ElMessage.success(t('form.update_success'))
    closeWin()
    submitLoading.value = false
  } catch (error) {
    submitLoading.value = false
  }
}
// 打开
const openWin = () => {
  winShow.value = true
}
// 关闭
const closeWin = () => {
  form.value.clearForm()
  winShow.value = false
}

defineExpose({
  openWin: openWin
})
</script>