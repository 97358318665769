import {createApp} from 'vue'
import App from './App.vue'
import router from "./router"
import components from "./components"
import store from './store'
import installElementPlus from './plugins/element'
import * as echarts from 'echarts'
import dayjs from 'dayjs'
import './icons' // 全部导入svg图标
import {
	userRole,
	time_to_date,
	userTextChange,
	userProduct,
	startTime,
	setFormItem,
	debounce,
	formatType,
	formatState,
	Enterpriseformat,
	formatApplicatState,
	changeUTCtime,
	numberToCurrencyNo
} from './utils/format'
import { getBrowserLang } from '@/utils/common'
// 修改浏览器页签名称
const language = localStorage.getItem('language') || getBrowserLang()
document.title = language !== 'en' ? '授权管理平台' : 'License'

const app = createApp(App)
app.use(components)
app.use(router)
app.use(store)
installElementPlus(app)
app.mount('#app')

// 注册全局方法
app.config.globalProperties.$formatApplicatState = formatApplicatState
app.config.globalProperties.$numberToCurrencyNo = numberToCurrencyNo
app.config.globalProperties.$changeUTCtime = changeUTCtime
app.config.globalProperties.$formatState = formatState
app.config.globalProperties.$Enterpriseformat = Enterpriseformat
app.config.globalProperties.$formatType = formatType
app.config.globalProperties.$userRole = userRole
app.config.globalProperties.$setFormItem = setFormItem
app.config.globalProperties.$time_to_date = time_to_date
app.config.globalProperties.$userTextChange = userTextChange
app.config.globalProperties.$debounce = debounce
app.config.globalProperties.$userProduct = userProduct
app.config.globalProperties.$startTime = startTime
app.config.globalProperties.$echarts = echarts
app.config.globalProperties.$dayjs = dayjs

window.app = app;
