<template>
  <Detail ref="detailRef"></Detail>
</template>
<script setup>
import Detail from './Detail.vue'
import { ref } from 'vue'
const detailRef = ref()
const openWin = async (data, showAssignList) => {
  await detailRef.value.openWin(data, showAssignList)
}
defineExpose({
  openWin
})
</script>