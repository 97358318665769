import store from '@/store'
import { productManagement } from '@/utils/api'
import { cloneDeep } from 'lodash'
import json2csv from 'json2csv'
import { saveAs } from 'file-saver'
import { ElLoading, ElMessage } from 'element-plus'
import dayjs from 'dayjs'
import { i18n } from '@/plugins/element'
const t = i18n.global.t

// 用户权限验证
export const userRole = (roleList) => {
  const permission = store.state.role
  return roleList.includes(permission)
}

// 时间格式化
export const time_to_date = (val) => {
  if (val) return val.toString().split(' ')[0]
  else return '-'
}

// 用户身份转换
export const userTextChange = (role_id) => {
  return role_id && t(`role.role${role_id}`) ? t(`role.role${role_id}`) : t('role.unkown')
}

// 订单类别
export const formatType = (state, failback = '') => {
  if (state === undefined || state === null) return '-'
  if (parseInt(state) === 1002 && failback === 'failback') {
    return `${t(`order.type_${state}`)}-${t('order.failback')}`
  }
  return t(`order.type_${state}`)
}

// 订单审批状态
export const formatState = (state, type = '') => {
  // type取值: 空只返回汉字, 非空返回html
  if (state === undefined || state === null) return '-'
  if (type) return `<p class='status_${state} approved'>${t(`order.status_${state}`)}</p>`
  return t(`order.status_${state}`)
}
// 企业审批状态
export const Enterpriseformat = (state, type = '') => {
  // type取值: 空只返回汉字, 非空返回html
  if (state === undefined || state === null) return '-'
  if (type) return `<p class='enterprise_status_${state} approved'>${t(`enterprise.status_${state}`)}</p>`
  return t(`enterprise.status_${state}`)
}
// 申请审批状态
export const formatApplicatState = (state, type = '') => {
  // type取值: 空只返回汉字, 非空返回html
  if (state === undefined || state === null) return '-'
  if (type) return `<p class='status_${state} approved'>${t(`assign.status_${state}`)}</p>`
  return t(`assign.status_${state}`)
}

// 表单内容设置
export const setFormItem = (labelList, prop, data, felid) => {
  labelList.forEach(item => {
    if (item.prop === prop) item[felid] = data
  })
}

// 用户产品
export const userProduct = (user_id = null) => {
  return new Promise((resolve, reject) => {
    let params = {}
    if (user_id) {
      params = {
        filter_user_id: user_id
      }
    }
    productManagement.ProductList({
      ...params,
      is_paginate: false
    }).then(res => {
      if (!res.items.length) {
        resolve([])
      } else {
        let arr = []
        res.items.forEach(ele => {
          arr.push({
            label: `${ele.deployment.name}/${ele.category.name}/${ele.name}`,
            value: ele.id
          })
        })
        resolve(arr)
      }
    })
  })
}

// input防抖处理
export const debounce = (fn, time) => {
  let timeout = null //创建定时器
  return function () {
    // 5.每当用户触发input事件  把前一个 setTimeout 清楚掉
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      // 7.这里进行防抖的内容
      fn.apply(this, arguments)
    }, time)
  }
}

// 时间字符串
export const startTime = (timeData) => {
  const date = timeData ? new Date(timeData) : new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()
  const add0 = (time) => {
    if (time < 10) {
      time = `0${time}`
    }
    return time
  }
  let times = `${year}-${add0(month)}-${add0(day)} ${add0(hour)}:${add0(minutes)}:${add0(seconds)}`
  times = new Date(times).toISOString()
  return `${times.substring(0, times.length - 5)}Z`
}

// utc时间转化
export const changeUTCtime = (time) => {
  return time ? dayjs(time).format('YYYY-MM-DD HH:mm:ss') : '-'
}

// 表格导出
export class CsvExport {
  constructor(api, query, resolver, filename) {
    this.api = api
    this.query = cloneDeep(query ?? {})
    this.resolver = resolver
    this.filename = filename
    this.lines = []
    this.loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
  }

  execute() {
    this.query.page = 1
    this.query.limit = 1000
    this.request()
  }

  request() {
    this.api(this.query).then(data => {
      data.items.map(item => {
        this.lines.push(this.resolver(item))
      })
      const pages = Math.ceil(data.total / data.per_page)
      if (pages > this.query.page) {
        this.query.page += 1
        this.request()
      } else {
        this.save()
      }
    }).catch(error => {
      console.log(error)
      ElMessage.success(t('table.export_failed'))
      this.loading.close()
    })
  }

  save() {
    const csv = json2csv.parse(this.lines)
    const blob = new Blob(['\uFEFF' + csv], {
      type: 'text/csv'
    })
    saveAs(blob, this.filename + '.csv')
    this.loading.close()
    ElMessage.success(t('table.export_success'))
  }
}
// 千分位，分割
export const numberToCurrencyNo = (value) => {
  if (!!!value) return 0
  if (value !== '-') {
    // 取整数部分
    const intPart = Math.trunc(value)
    // 整数部分处理，增加 ，
    const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    // 预定义小数部分
    let floatPart = ''
    // 将数值截取为小数部分和整数部分
    const valueArray = value.toString().split('.')
    if (valueArray.length === 2) {
      // 有小数部分
      floatPart = value[1].toString()
      return intPartFormat + '.' + floatPart
    }
    return intPartFormat + floatPart
  }
}

// 时间格式化处理
let dateFieldList = [
  'created_at',
  'expired_at',
  'start_at',
  'login_at',
  'updated_at',
  'approval_at'
]
export const formateDate = (dataList, type = null) => {
  if (type) {
    for (const key in dataList) {
      if (dateFieldList.includes(key)) {
        dataList[key] = dataList[key] ? dayjs(dataList[key]).format('YYYY-MM-DD HH:mm:ss') : '-'
      }
    }
    return
  }
  dataList.forEach(item => {
    if (dateFieldList.includes(item.prop) && item.value !== '-') {
      item.value = item.value ? dayjs(item.value).format('YYYY-MM-DD HH:mm:ss') : '-'
    } else if (item.prop === 'comment') {
      item.value = item.value.replaceAll('\n', '<br />')
    }
  })
}