import axios from 'axios'
import {
  ElMessage, ElMessageBox
} from 'element-plus'
import qs from "qs"
import store from '@/store'
import { getBrowserLang } from './common'
import { i18n } from '@/plugins/element'
const t = i18n.global.t

axios.defaults.timeout = 20000 //请求超时时间
axios.defaults.baseURL = process.env.VUE_APP_API_URI //请求域名

const http = axios.create({
  paramsSerializer(params) {
    return qs.stringify(params, {
      encode: false
    })
  }
})

// 请求拦截器
http.interceptors.request.use(options => {
  const browserLang = getBrowserLang() === 'zh-cn' ? 'zh' : 'en'
  const xLang = localStorage.getItem('language') && localStorage.getItem('language').toLowerCase() === 'zh-cn' ? 'zh' : 'en'
  if (!options.headers.Authorization) {
    options.headers.Authorization = store.state.authorization
  }
  options.headers = {
    ...options.headers,
    'X-LANG': xLang || browserLang, // 语言
  }
  return options
}, error => {
  return Promise.reject(error)
})

// 响应拦截器
http.interceptors.response.use(response => {
  if (response.status == 200) {
    if (response.data.error && JSON.stringify(response.error) !== '{}') {
      ElMessage.error(response.data.error)
    } else {
      return response
    }
  }
}, error => {
  // 网络连接失败
  if (error.response === undefined) {
    ElMessage.error(t('system.network_error'))
    return
  } else {
    let time = 1
    if (error.response.status) {
      switch (error.response.status) {
        case 422:
          ElMessage.error(Object.values(error.response.data.error.fields)[0])
          break;
        case 500:
          if (error.response.data.title)
            ElMessage.error(error.response.data.title);
          else ElMessage.error(t('system.network_500'))
          break;
        case 401:
          let errorTips = ""
          if (error.response.data.title) {
            errorTips = error.response.data.title
          } else {
            errorTips = error.response.data.title
          }
          ElMessageBox.close()
          if (time === 1) {
            ElMessageBox.alert(errorTips, t('system.tip'), {
              confirmButtonText: t('form.submit'),
              callback: () => {
                store.commit('CLEAR_USER')
              },
            })
            time++
          }
          break;
        case 404:
          if (error.response.data.title)
            ElMessage.error(error.response.data.title)
          else ElMessage.error(t('system.error_404'))
          break;
        default:
          break;
      }
    }
  }
  return Promise.reject(error.response)
})

export default http
