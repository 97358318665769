<template>
  <!-- 分页 -->
  <div v-if="totalPage" class="page">
    <el-pagination
      :total="parseInt(totalPage)"
      :page-size="pageSize"
      :current-page="currentPage"
      background
      @current-change="setCurrent"
      layout="total, prev, pager, next"
      size="small"
      class="mt-4" />
  </div>
</template>

<script setup>
  import { ref } from 'vue'
	const props = defineProps({
		totalPage: {
			type: Number,
			default: 1
		},
		//当前页
		pageSize: {
			type: Number,
			default: 1
		}
	})

  const currentPage = ref(1)

	// 分页改变触发接口请求数据
	const emit = defineEmits(['getList'])
	const setCurrent = (current) => {
    currentPage.value = current
		emit('getList',{
			page: current
		})
	}
  // 重置分页
  const resetCurrent = (current = null) => {
    currentPage.value = current || 1
  }
  defineExpose({
    resetCurrent
  })
</script>
<style lang="scss" scoped>
.page {
	width: 100%;
	margin-top: 10px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 10px;
	.totals {
		font-size: 14px;
		margin-right: 10px;
	}
}
</style>